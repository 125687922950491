/**
 * A dialog component for confirming user actions with customizable buttons and loading states.
 *
 * @example
 * // Simple delete confirmation
 * <ConfirmActionDialog
 *   title="Delete Item"
 *   confirmButton={{
 *     title: "Delete",
 *     loadingTitle: "Deleting...",
 *     theme: "destructive",
 *     icon: TrashIcon,
 *   }}
 *   isLoading={isDeleting}
 *   onConfirm={handleDelete}
 *   setClosed={closeModal}
 * />
 *
 * @example
 * // Publishing confirmation with custom cancel button
 * <ConfirmActionDialog
 *   title="Publish Article"
 *   description="This will make the article visible to all users."
 *   confirmButton={{
 *     title: "Publish",
 *     loadingTitle: "Publishing...",
 *     theme: "primary",
 *   }}
 *   cancelButton={{
 *     title: "Save as Draft",
 *     loadingTitle: "Saving...",
 *   }}
 *   isLoading={isPublishing}
 *   onConfirm={handlePublish}
 *   onCancel={handleSaveAsDraft}
 *   setClosed={closeModal}
 * />
 *
 * @example
 * // Destructive action with disabled cancel
 * <ConfirmActionDialog
 *   title="Delete Account"
 *   confirmButton={{
 *     title: "Delete Permanently",
 *     theme: "destructive",
 *   }}
 *   cancelButton={{
 *     disabled: true,
 *   }}
 *   isLoading={isDeleting}
 *   onConfirm={handleAccountDeletion}
 *   setClosed={closeModal}
 * />
 */

import Button, { ButtonTheme } from './Button';
import Modal from './Modal';
import { WithOptional } from '../types/utils';
import { SvgIconComponent } from '../types/svgs';

export type ConfirmActionDialogProps = {
  title: string;
  description?: string;
  // Button configurations
  confirmButton: {
    title: string;
    loadingTitle?: string;
    theme?: ButtonTheme;
    icon?: SvgIconComponent;
  };
  cancelButton?: {
    title?: string;
    loadingTitle?: string;
    disabled?: boolean;
  };
  // State and callbacks
  isLoading?: boolean;
  onConfirm: () => Promise<unknown>;
  onCancel?: () => void;
  isOpen: boolean;
  setClosed: () => void;
} & Omit<
  WithOptional<Parameters<typeof Modal>[0], 'children'>,
  'isOpen' | 'setClosed'
>;

export default function ConfirmActionDialog({
  title,
  description,
  confirmButton,
  cancelButton = {},
  isLoading = false,
  children,
  isOpen,
  setClosed,
  onConfirm,
  onCancel,
  ...rest
}: ConfirmActionDialogProps) {
  const {
    title: confirmTitle,
    loadingTitle: confirmLoadingTitle,
    theme: confirmTheme = 'primary',
    icon: ConfirmIcon,
  } = confirmButton;

  const {
    title: cancelTitle = 'Cancel',
    loadingTitle: cancelLoadingTitle,
    disabled: cancelDisabled,
  } = cancelButton;

  const handleCancel = () => {
    onCancel?.();
    setClosed();
  };

  const handleConfirm = async () => {
    await onConfirm();
    setClosed();
  };

  return (
    <Modal
      name="ConfirmAction"
      width="small"
      isOpen={isOpen}
      setClosed={setClosed}
      hasCloseRow={false}
      preventEventPropagation={true}
      {...rest}
    >
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center px-4 text-center">
        <h1 className="mt-3 font-serif text-subtitle-small text-green-150">
          {title}
        </h1>
        <div className="mt-2 mb-8 w-full text-center font-sans text-body text-green-125">
          {children || description || 'Are you sure you want to proceed?'}
        </div>
        <div className="mb-5 flex w-full flex-row items-center justify-center">
          <Button
            title={
              isLoading && cancelLoadingTitle ? cancelLoadingTitle : cancelTitle
            }
            theme="secondary"
            className="mr-5"
            disabled={isLoading || cancelDisabled}
            onClick={handleCancel}
          />
          <Button
            theme={confirmTheme}
            IconComponent={ConfirmIcon}
            iconPosition={ConfirmIcon ? 'left' : undefined}
            title={
              isLoading && confirmLoadingTitle
                ? confirmLoadingTitle
                : confirmTitle
            }
            disabled={isLoading}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </Modal>
  );
}
