import { useNavigate } from 'react-router-dom';
import { UserGroupIcon } from '@heroicons/react/outline';
import {
  NylasCalendarEventDataFragment,
  useCreateClientSessionFromEventMutation,
  useNylasPrimaryCalendarQuery,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { CLIENT_SESSIONS_ACTIVE_BASE_PATH } from '../../../ClientSessions/utils/constants';
import { logger } from '../../../../../lib/logger';
import ConfirmActionDialog from '../../../../components/ConfirmActionDialog';
import { useCalendars } from '../hooks/useCalendars';

interface CreateSessionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  event: NylasCalendarEventDataFragment;
}

export const CreateSessionDialog: React.FC<CreateSessionDialogProps> = ({
  isOpen,
  onClose,
  event,
}) => {
  const navigate = useNavigate();
  const { getCalendarByEmail } = useCalendars();
  const { data: primaryCalendarData } = useNylasPrimaryCalendarQuery();
  const [createClientSession, { loading: isCreating }] =
    useCreateClientSessionFromEventMutation();

  const handleCreateSession = async () => {
    try {
      // Try to find the calendar by email first
      let calendar = getCalendarByEmail(event.calendarId);

      // If no calendar found, use the primary calendar as a fallback
      if (!calendar?.id) {
        logger.debug(
          'Calendar not found by email, using primary calendar as fallback',
          {
            eventCalendarId: event.calendarId,
            primaryCalendarId: primaryCalendarData?.nylasPrimaryCalendar?.id,
          },
        );

        calendar = primaryCalendarData?.nylasPrimaryCalendar;

        if (!calendar?.id) {
          throw new Error(
            'Calendar not found and no primary calendar available',
          );
        }
      }

      const response = await createClientSession({
        variables: {
          input: {
            calendarId: calendar.id,
            eventId: event.id,
          },
        },
      });

      if (response.data?.createClientSessionFromEvent.success) {
        customToast.success('Client session created successfully');

        // Get the session ID from the response
        const sessionId =
          response.data?.createClientSessionFromEvent.clientSession?.id;

        if (sessionId) {
          // Navigate to the specific session page
          navigate(`${CLIENT_SESSIONS_ACTIVE_BASE_PATH}/${sessionId}`);
        } else {
          // Fallback to the pending sessions page if no ID is available
          navigate(CLIENT_SESSIONS_ACTIVE_BASE_PATH);
        }
      } else {
        const error =
          response.data?.createClientSessionFromEvent.error ||
          'Unknown error creating client session';
        customToast.error(error);
      }
    } catch (error) {
      logger.error('Error creating client session:', error);
      customToast.error('Failed to create client session');
    } finally {
      onClose();
    }
  };

  return (
    <ConfirmActionDialog
      isOpen={isOpen}
      setClosed={onClose}
      title="Create client session"
      confirmButton={{
        title: 'Create',
        loadingTitle: 'Creating...',
        theme: 'primary',
        icon: UserGroupIcon,
      }}
      isLoading={isCreating}
      onConfirm={handleCreateSession}
    >
      Are you sure you want to create a client session for "{event.title}"?
    </ConfirmActionDialog>
  );
};
