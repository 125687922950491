import { FC } from 'react';
import EmailRequestPage from './EmailRequestPage';
import CodeVerificationPage from './CodeVerificationPage';

interface PatientLoginProps {
  page: 'email' | 'verify';
}

const PatientLogin: FC<PatientLoginProps> = ({ page }) => {
  // Render the appropriate component based on the page prop
  if (page === 'verify') {
    return <CodeVerificationPage />;
  }

  // Default to email request page
  return <EmailRequestPage />;
};

export default PatientLogin;
