import React from 'react';
import ModalDialog from '../../../../components/ModalDialog';
import EventForm from './EventForm';
import { CreateEventFormData, CreateEventModalProps } from './types';
import { useEventMutation } from '../hooks/useEventMutations';
import { getDefaultEventFormValues } from '../utils/formUtils';

const CreateEventModal: React.FC<CreateEventModalProps> = ({
  isOpen,
  onClose,
  selectInfo,
  calendarId,
  onEventCreated,
}) => {
  const defaultValues = getDefaultEventFormValues(selectInfo);
  const { handleCreateEvent } = useEventMutation();

  const handleSubmit = async (data: CreateEventFormData) => {
    const result = await handleCreateEvent(data, calendarId);
    if (result.success) {
      onEventCreated?.(result.event);
      onClose?.();
    }
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      setClosed={onClose}
      title="Create Event"
      width="small"
    >
      <EventForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        isAllDay={defaultValues.isAllDay}
      />
    </ModalDialog>
  );
};

export default CreateEventModal;
