import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { customToast } from '../../../../components/ToastAlert/customToast';

import { InitialState } from './InitialState';
import { PendingState } from './PendingState';
import { VerifiedState } from './VerifiedState';
import { SenderFormData } from './types';
import { useBrandSettings } from './hooks/useBrandSettings';
import { useCooldownTimer } from './hooks/useCooldownTimer';
import { BrandingVerifiedEmailSenderStatus } from '../../../../../generated/graphql';
import { useCreateVerifiedEmailSender } from './hooks/useCreateVerifiedEmailSender';
import { useVerifyEmailSender } from './hooks/useVerifyEmailSender';
import { useDeleteVerifiedEmailSender } from './hooks/useDeleteVerifiedEmailSender';

export const VerifiedEmailSenderSection: FC = () => {
  const {
    brandSettings,
    loading: loadingBrandSettings,
    refetch: refetchBrandSettings,
  } = useBrandSettings();
  const { cooldownTime, startCooldownTimer, clearCooldownTimer } =
    useCooldownTimer();

  // Simplified state management - set directly from brandSettings
  const [verifiedSenderState, setVerifiedSenderState] =
    useState<BrandingVerifiedEmailSenderStatus | null>(
      brandSettings?.verifiedEmailSender?.status || null,
    );

  // Add this effect to update state when brandSettings changes
  useEffect(() => {
    if (brandSettings?.verifiedEmailSender) {
      setVerifiedSenderState(brandSettings.verifiedEmailSender.status);
    } else {
      setVerifiedSenderState(null);
    }
  }, [brandSettings]);

  const {
    register: senderRegister,
    handleSubmit: handleSenderSubmit,
    formState: { errors: senderErrors },
    reset: resetSenderForm,
    // setValue,
    setError,
  } = useForm<SenderFormData>({
    defaultValues: {
      fromName: brandSettings?.verifiedEmailSender?.fromName || '',
      fromEmail: brandSettings?.verifiedEmailSender?.fromEmail || '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
  });

  const setFormErrors = (field: keyof SenderFormData, message: string) => {
    setError(field, { type: 'manual', message });
  };

  const handleCreateSuccess = () => {
    setVerifiedSenderState(BrandingVerifiedEmailSenderStatus.Pending);
    startCooldownTimer(60);
    refetchBrandSettings();
  };

  const { createSender, loading: createLoading } = useCreateVerifiedEmailSender(
    handleCreateSuccess,
    setFormErrors,
  );

  const handleVerifySuccess = () => {
    setVerifiedSenderState(BrandingVerifiedEmailSenderStatus.Verified);
    clearCooldownTimer();
    refetchBrandSettings();
  };

  const { verifySender, loading: verifyLoading } =
    useVerifyEmailSender(handleVerifySuccess);

  const handleDeleteSuccess = () => {
    setVerifiedSenderState(null);
    resetSenderForm();
    refetchBrandSettings();
  };

  const { deleteSender, loading: deleteLoading } =
    useDeleteVerifiedEmailSender(handleDeleteSuccess);

  const onSenderSubmit = async (data: SenderFormData) => {
    await createSender(data);
  };

  const onVerifySender = async () => {
    await verifySender();
  };

  const resendVerification = async () => {
    try {
      // Stub API call to resend verification
      console.log('Resending verification');
      // In a real implementation, you would call your API here
      // await resendVerification();

      // Reset cooldown to 60 seconds
      startCooldownTimer(60);

      customToast.success(
        'Verification email resent. Please check your inbox.',
      );
    } catch (error) {
      customToast.error('Failed to resend verification. Please try again.');
    }
  };

  const removeSender = async () => {
    await deleteSender();
  };

  const cancelVerification = async () => {
    try {
      await deleteSender();
      clearCooldownTimer();
    } catch (error) {
      customToast.error('Failed to cancel verification. Please try again.');
    }
  };

  if (loadingBrandSettings) {
    return <div className="mb-8">Loading...</div>;
  }

  return (
    <div className="mb-8">
      <div className="mb-1 text-caption font-medium text-green-150">
        Verify your email address
      </div>
      <div className="mb-3 text-small-caption text-neutral-125">
        Customize the sender name and email address for outgoing emails. This
        must be your own domain and cannot be an address from a third party
        email provider like Gmail or Outlook.
      </div>

      {verifiedSenderState === null && (
        <InitialState
          senderRegister={senderRegister}
          handleSenderSubmit={handleSenderSubmit}
          senderErrors={senderErrors}
          onSenderSubmit={onSenderSubmit}
          isLoading={createLoading}
        />
      )}

      {verifiedSenderState === BrandingVerifiedEmailSenderStatus.Pending && (
        <PendingState
          cooldownTime={cooldownTime}
          onVerifySender={onVerifySender}
          resendVerification={resendVerification}
          cancelVerification={cancelVerification}
          emailSender={brandSettings?.verifiedEmailSender}
          isVerifyLoading={verifyLoading}
        />
      )}

      {verifiedSenderState === BrandingVerifiedEmailSenderStatus.Verified && (
        <VerifiedState
          removeSender={removeSender}
          emailSender={brandSettings?.verifiedEmailSender}
          isLoading={deleteLoading}
        />
      )}
    </div>
  );
};
