import { customToast } from '../../../../../components/ToastAlert/customToast';
import { useBrandingVerifyEmailSenderMutation } from '../../../../../../generated/graphql';

export const useVerifyEmailSender = (onSuccess: () => void) => {
  const [verifyEmailSender, { loading }] =
    useBrandingVerifyEmailSenderMutation();

  const verifySender = async () => {
    try {
      const response = await verifyEmailSender();
      const result = response.data?.brandingVerifyEmailSender;

      if (result?.success) {
        if (result.verified) {
          customToast.success('Email address verified successfully!');
          onSuccess();
          return true;
        } else {
          customToast.warning(
            'Verification is still in progress. Please check back later.',
          );
          return false;
        }
      } else if (result?.errors && result.errors.length > 0) {
        result.errors.forEach((error) => {
          customToast.error(error.message);
        });
        return false;
      } else {
        customToast.error('Failed to verify sender. Please try again.');
        return false;
      }
    } catch (error: any) {
      const errorMessage =
        error.message || 'Failed to verify sender. Please try again.';
      customToast.error(errorMessage);
      return false;
    }
  };

  return {
    verifySender,
    loading,
  };
};
