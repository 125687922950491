import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEX } from '../../../lib/regex';
import InputGroup from '../../../components/InputGroup';
import Button from '../../../components/Button';

export interface EmailFormData {
  email: string;
}

interface EmailFormProps {
  onSubmit: (data: EmailFormData) => Promise<void>;
  isSubmitting: boolean;
  maskedEmail: string | null;
  nextResendAllowedAt: string | null;
  onResendCode: () => void;
}

const EmailForm: FC<EmailFormProps> = ({
  onSubmit,
  isSubmitting,
  maskedEmail,
  nextResendAllowedAt,
  onResendCode,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailFormData>({
    mode: 'onSubmit',
  });

  // Calculate time remaining for resend if nextResendAllowedAt is provided
  const calculateTimeRemaining = (): string => {
    if (!nextResendAllowedAt) return '';

    const timeLeft = new Date(nextResendAllowedAt).getTime() - Date.now();
    if (timeLeft <= 0) return '';

    const minutes = Math.floor(timeLeft / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const timeRemaining = calculateTimeRemaining();

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full text-left">
        <InputGroup
          placeholder="Email address"
          type="email"
          autoComplete="username"
          inputSize="small"
          useNaturalLettering={true}
          containerClassName="mb-2"
          required
          errorMessage={errors.email?.message}
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email',
            },
          })}
        />
      </div>

      <Button
        title={maskedEmail ? 'Resend Code' : 'Send Code'}
        className="mx-auto my-6 w-full md:mt-8"
        type="submit"
        disabled={isSubmitting || !!timeRemaining}
        onClick={handleSubmit(onSubmit)}
      />

      {maskedEmail && (
        <div className="rounded-lg bg-green-25 p-4 text-center text-caption">
          We've sent a verification code to {maskedEmail}.
          {timeRemaining && (
            <div className="mt-2">
              You can request a new code in {timeRemaining}
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default EmailForm;
