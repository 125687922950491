import { useCallback, useState } from 'react';
import {
  ClientSessionIndividual,
  useGetClientSessionLazyQuery,
} from '../../../../generated/graphql';
import { SessionModalMode, SessionModalType } from '../types';
import { logger } from '../../../../lib/logger';
import { customToast } from '../../../components/ToastAlert/customToast';
import { ClientSessionReviewStatus } from '../../../../generated/graphql';

export const useSessionModal = () => {
  const [modalType, setModalType] = useState<SessionModalType>(null);
  const [selectedSession, setSelectedSession] =
    useState<ClientSessionIndividual | null>(null);
  const [getClientSession] = useGetClientSessionLazyQuery();

  const handleModalOpen = useCallback(
    async (sessionId: string) => {
      try {
        // Fetch session data using the ID
        const { data, error } = await getClientSession({
          variables: { id: sessionId },
        });

        if (error || !data?.clientSession) {
          logger.error('Error fetching session:', error);
          customToast.error('Unable to load session details');
          return;
        }

        const fetchedSession = data.clientSession as ClientSessionIndividual;

        // Determine the modal mode based on the session's status
        const modalMode =
          fetchedSession.reviewStatus ===
          ClientSessionReviewStatus.PendingReview
            ? SessionModalMode.REVIEW
            : SessionModalMode.UPDATE;

        setSelectedSession(fetchedSession);
        setModalType(modalMode);
      } catch (err) {
        logger.error('Error in handleModalOpen:', err);
        customToast.error('Unable to load session details');
      }
    },
    [getClientSession],
  );

  const handleModalClose = useCallback(() => {
    setSelectedSession(null);
    setModalType(null);
  }, []);

  return {
    modalType,
    selectedSession,
    openModal: handleModalOpen,
    closeModal: handleModalClose,
  };
};
