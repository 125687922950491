import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';

const splitText = (text: string, splitBy: string | null = '@'): string => {
  if (splitBy === null) return text;
  return text.split(splitBy)[0].trim();
};

const truncateText = (
  text: string,
  truncateAfter: number | null = 10,
): string => {
  if (truncateAfter === null) return text;
  return text.length > truncateAfter
    ? text.slice(0, truncateAfter).trim() + '...'
    : text;
};

interface ViewSelectorProps<T> {
  items: T[];
  selectedIds: string[];
  onChange: (ids: string[]) => void;
  getItemId: (item: T) => string;
  getItemLabel: (item: T) => string;
  loading?: boolean;
  splitBy?: string | null;
  truncateAfter?: number | null;
}

export function ViewSelector<T>({
  items,
  selectedIds,
  onChange,
  getItemId,
  getItemLabel,
  loading,
  splitBy = '@',
  truncateAfter = 10,
}: ViewSelectorProps<T>) {
  const safeSelectedIds = selectedIds || [];

  return (
    <Combobox value={safeSelectedIds} onChange={onChange} multiple>
      <div className="relative mt-1 w-full">
        <Combobox.Button className="w-full focus:outline-none focus:ring-0">
          <Combobox.Input
            className="text-sm w-full truncate rounded-md border border-neutral-75 py-2 pl-3 pr-3 focus:outline-none focus:ring-0"
            displayValue={(ids: string[]) => {
              const displayText = ids
                ?.map((id) => {
                  const item = items.find((i) => getItemId(i) === id);
                  if (!item) return '';
                  const label = getItemLabel(item);
                  const text = splitText(label, splitBy);
                  return truncateText(text, truncateAfter);
                })
                .filter(Boolean)
                .join(', ');

              return displayText;
            }}
          />
        </Combobox.Button>
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg">
          {loading ? (
            <div className="text-sm px-4 py-2 text-neutral-125">Loading...</div>
          ) : (
            items.map((item) => (
              <Combobox.Option
                key={getItemId(item)}
                value={getItemId(item)}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-green-50 text-green-150' : 'text-neutral-150'
                  }`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {getItemLabel(item)}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-150">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
