import { useBrandingBrandSettingsQuery } from '../../../../../../generated/graphql';

export const useBrandSettings = () => {
  const { data, loading, error, refetch } = useBrandingBrandSettingsQuery();

  return {
    brandSettings: data?.brandingBrandSettings,
    loading,
    error,
    refetch,
  };
};
