import { FC } from 'react';
import { useForm } from 'react-hook-form';
import InputGroup from '../../../../components/InputGroup';
import TextAreaGroup from '../../../../components/TextAreaGroup';
import { formatDateForInput, formatTimeForInput } from '../../../../lib/time';
import { ClientSession } from '../../../../../generated/graphql';
import Button from '../../../../components/Button';
import { CommonWhenType } from '../../../../../generated/graphql';

interface EditClientSessionFormData {
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  date: string;
  // TODO: Add practitioners and client selection
}

interface EditClientSessionFormProps {
  session: ClientSession;
  onSubmit: (data: {
    id: string;
    title: string;
    description: string;
    when: {
      type: CommonWhenType;
      startTime: string;
      endTime: string;
    };
  }) => Promise<{
    success: boolean;
    errors?: Array<{ field: string; message: string }>;
  }>;
}

const EditClientSessionForm: FC<EditClientSessionFormProps> = ({
  session,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    reset: resetForm,
    clearErrors,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<EditClientSessionFormData>({
    defaultValues: {
      title: session.title || '',
      description: session.description || '',
      date: formatDateForInput(session.startTime),
      startTime: formatTimeForInput(session.startTime),
      endTime: formatTimeForInput(session.endTime),
      // TODO: Add practitioners and client selection
    },
  });

  const handleFormSubmit = async (formData: EditClientSessionFormData) => {
    try {
      // Format the data according to the GraphQL schema
      const input = {
        id: session.id,
        title: formData.title,
        description: formData.description,
        when: {
          type: CommonWhenType.Timespan,
          startTime: new Date(
            `${formData.date}T${formData.startTime}`,
          ).toISOString(),
          endTime: new Date(
            `${formData.date}T${formData.endTime}`,
          ).toISOString(),
        },
      };

      return await onSubmit(input);
    } catch (err) {
      console.error('Error formatting session update:', err);
      return {
        success: false,
        errors: [{ field: 'root', message: 'Failed to update session' }],
      };
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="mb-8 space-y-6 border-b border-neutral-75 pb-8"
    >
      <div className="mb-6 text-center">
        <h2 className="font-serif text-subtitle text-green-150">
          Session Details
        </h2>
        <p className="text-caption text-neutral-125">
          Update the basic information for this session.
        </p>
      </div>

      <div className="space-y-4">
        <InputGroup
          label="Title"
          {...register('title', { required: 'Title is required' })}
          errorMessage={errors.title?.message}
        />

        <TextAreaGroup
          label="Description"
          {...register('description')}
          errorMessage={errors.description?.message}
        />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <InputGroup
            label="Date"
            type="date"
            {...register('date', { required: 'Date is required' })}
            errorMessage={errors.date?.message}
          />
          <InputGroup
            label="Start Time"
            type="time"
            {...register('startTime', { required: 'Start time is required' })}
            errorMessage={errors.startTime?.message}
          />
          <InputGroup
            label="End Time"
            type="time"
            {...register('endTime', { required: 'End time is required' })}
            errorMessage={errors.endTime?.message}
          />
        </div>

        {/* TODO: Add practitioners and client selection fields */}

        <div className="flex justify-center space-x-4">
          <Button
            title="Reset"
            onClick={() => {
              resetForm();
            }}
            theme="secondary-white"
            size="medium"
            disabled={!isDirty}
          />
          <Button
            title={isSubmitting ? 'Saving...' : 'Save changes'}
            disabled={isSubmitting || !isDirty}
            onClick={() => {
              clearErrors();
              handleSubmit(handleFormSubmit);
            }}
            type="submit"
            theme="secondary"
            size="medium"
          />
        </div>
      </div>
    </form>
  );
};

export default EditClientSessionForm;
