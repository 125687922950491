import { FC } from 'react';
import {
  FieldErrors,
  UseFormRegister,
  UseFormHandleSubmit,
} from 'react-hook-form';
import Button from '../../../../components/Button';
import InputGroup from '../../../../components/InputGroup';
import { SenderFormData } from './types';

interface InitialStateProps {
  senderRegister: UseFormRegister<SenderFormData>;
  handleSenderSubmit: UseFormHandleSubmit<SenderFormData>;
  senderErrors: FieldErrors<SenderFormData>;
  onSenderSubmit: (data: SenderFormData) => Promise<void>;
  isLoading: boolean;
}

export const InitialState: FC<InitialStateProps> = ({
  senderRegister,
  handleSenderSubmit,
  senderErrors,
  onSenderSubmit,
  isLoading,
}) => {
  return (
    <form onSubmit={handleSenderSubmit(onSenderSubmit)}>
      <InputGroup
        label="From name"
        placeholder="Homecoming"
        className="mb-3 rounded-lg"
        {...senderRegister('fromName', {
          required: 'From name is required',
        })}
        errorMessage={senderErrors.fromName?.message}
        useNaturalLettering
      />
      <InputGroup
        label="From email"
        placeholder="support@homecoming.health"
        className="mb-3 rounded-lg"
        {...senderRegister('fromEmail', {
          required: 'From email is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Please enter a valid email address',
          },
        })}
        errorMessage={senderErrors.fromEmail?.message}
        useNaturalLettering
      />

      <div className="mt-2 mb-1 text-caption font-medium text-green-150">
        Organization address
      </div>
      <div className="mb-3 text-small-caption text-neutral-125">
        This is required information in order to comply with anti-spam laws such
        as CAN-SPAM and CASL.
      </div>

      <InputGroup
        label="Address line 1"
        placeholder="123 Main St"
        className="mb-3 rounded-lg"
        {...senderRegister('address', {
          required: 'Address is required',
        })}
        errorMessage={senderErrors.address?.message}
        useNaturalLettering
      />
      <InputGroup
        label="Address line 2 (optional)"
        placeholder="Suite 100"
        className="mb-3 rounded-lg"
        {...senderRegister('address2')}
        errorMessage={senderErrors.address2?.message}
        useNaturalLettering
      />
      <div className="mb-3 flex gap-3">
        <InputGroup
          label="City"
          placeholder="San Francisco"
          {...senderRegister('city', {
            required: 'City is required',
          })}
          errorMessage={senderErrors.city?.message}
          useNaturalLettering
        />
        <InputGroup
          label="State/Province"
          placeholder="CA"
          {...senderRegister('state', {
            required: 'State is required',
            pattern: {
              value: /^[A-Z]{2}$/,
              message: 'Please enter 2 uppercase letters (e.g., CA)',
            },
            setValueAs: (v: string) => v.toUpperCase(),
          })}
          errorMessage={senderErrors.state?.message}
          useNaturalLettering
        />
      </div>
      <div className="mb-3 flex gap-3">
        <InputGroup
          label="ZIP/Postal code"
          placeholder="94105"
          {...senderRegister('zip', {
            required: 'ZIP code is required',
          })}
          errorMessage={senderErrors.zip?.message}
          useNaturalLettering
        />
        <InputGroup
          label="Country"
          placeholder="United States"
          {...senderRegister('country', {
            required: 'Country is required',
          })}
          errorMessage={senderErrors.country?.message}
          useNaturalLettering
        />
      </div>

      <Button
        size="small"
        title={isLoading ? 'Requesting...' : 'Request verification'}
        type="submit"
        noFocus
        disabled={isLoading}
      />
    </form>
  );
};
