import { useState, useRef, useEffect } from 'react';

export const useCooldownTimer = () => {
  const [cooldownTime, setCooldownTime] = useState(0);
  const cooldownEndTimeRef = useRef<number | null>(null);
  const cooldownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Cleanup function to clear interval when component unmounts
    return () => {
      if (cooldownIntervalRef.current) {
        clearInterval(cooldownIntervalRef.current);
      }
    };
  }, []);

  const startCooldownTimer = (durationInSeconds: number) => {
    // Clear any existing interval
    if (cooldownIntervalRef.current) {
      clearInterval(cooldownIntervalRef.current);
    }

    // Set the end time
    const endTime = Date.now() + durationInSeconds * 1000;
    cooldownEndTimeRef.current = endTime;

    // Set initial cooldown time
    const initialRemaining = Math.ceil((endTime - Date.now()) / 1000);
    setCooldownTime(initialRemaining);

    // Start the interval
    cooldownIntervalRef.current = setInterval(() => {
      if (cooldownEndTimeRef.current) {
        const remaining = Math.ceil(
          (cooldownEndTimeRef.current - Date.now()) / 1000,
        );

        if (remaining <= 0) {
          setCooldownTime(0);
          clearInterval(cooldownIntervalRef.current!);
          cooldownIntervalRef.current = null;
          cooldownEndTimeRef.current = null;
        } else {
          setCooldownTime(remaining);
        }
      }
    }, 1000);
  };

  const clearCooldownTimer = () => {
    if (cooldownIntervalRef.current) {
      clearInterval(cooldownIntervalRef.current);
      cooldownIntervalRef.current = null;
    }
    cooldownEndTimeRef.current = null;
    setCooldownTime(0);
  };

  return {
    cooldownTime,
    startCooldownTimer,
    clearCooldownTimer,
  };
};
