import { useCallback } from 'react';
import { useSearchCalendarParticipantsQuery } from '../../../../../generated/graphql';
import { SearchOption } from '../../../../components/SearchableCombobox';

const LIMIT_RESULTS = 4;

export const useParticipantSearch = () => {
  const { refetch } = useSearchCalendarParticipantsQuery({
    skip: true,
  });

  const searchParticipants = useCallback(
    async (query: string): Promise<SearchOption[]> => {
      if (!query.trim()) {
        return [];
      }

      const { data: refetchedData } = await refetch({
        searchQuery: query,
        limit: LIMIT_RESULTS,
      });

      const results = refetchedData?.patientsV2.map(
        (participant: {
          name?: string;
          email: string;
          profileImageMedia?: { url: string };
        }) => ({
          name: participant.name || '',
          email: participant.email,
          value: participant.email,
        }),
      );

      return results || [];
    },
    [refetch],
  );

  return { searchParticipants };
};
