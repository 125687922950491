import { FC, ReactNode } from 'react';
import hcLogo from '../../../../assets/images/logo/logo-green-xl.png';
import { HOMEPAGE_URL } from '../../../lib/constants';
import WordmarkLogo from '../../../svgs/WordmarkLogo';

const DEFAULT_TESTIMONIAL_QUOTE =
  "I spent so much time trying to cobble together a solution to keep track of all my client communication, plus take care of all the admin tasks like scheduling and billing. It was exhausting and took me out of the focused mindset I need to be an effective practitioner. Now, I can manage my whole business in one place, and it's much easier to keep things on track.";
const DEFAULT_TESTIMONIAL_NAME = 'Jules Xenakis';
const DEFAULT_TESTIMONIAL_TITLE = 'Coach, Being True to You';

interface LoginContainerProps {
  children: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
}

const LoginContainer: FC<LoginContainerProps> = ({
  children,
  title,
  description,
}) => {
  return (
    <div className="flex flex-col justify-start lg:min-h-screen lg:flex-row">
      <div className="flex h-[32px] flex-1 flex-wrap items-center justify-center gap-x-5 pt-screen-5 lg:absolute lg:top-7 lg:left-10 lg:p-0">
        <div>
          <a href={HOMEPAGE_URL} rel="noreferrer" target="_blank">
            <WordmarkLogo className="hidden h-[32px] lg:flex" />
            <img
              src={hcLogo}
              className="flex h-[32px] w-[32px] lg:hidden"
              alt="logo"
            />
          </a>
        </div>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center pb-screen-5 pt-screen-5 text-green-150">
        {title && (
          <div className="mb-3 mt-3 max-w-[488px] px-4 text-center font-serif text-subtitle-small font-light md:mb-2 md:text-subtitle">
            {title}
          </div>
        )}
        {description && (
          <div className="mb-8 max-w-[488px] px-4 text-center text-caption">
            {description}
          </div>
        )}

        <div className="w-3/4 max-w-[488px]">{children}</div>
      </div>
      <div className="w-full lg:w-[560px]">
        <div className="flex h-full w-full shrink flex-col items-center justify-center bg-cover p-8 lg:m-0 lg:bg-[url('assets/images/signup/side-bg.png')]">
          <div className="flex h-auto w-[348px] flex-col items-start justify-between rounded-md bg-white p-6 shadow-100">
            <div className="mb-4 text-body text-green-150">
              "{DEFAULT_TESTIMONIAL_QUOTE}"
            </div>
            <div className="flex flex-row items-center justify-start">
              <div className="mr-3">
                <div className="h-[50px] w-[50px] shrink bg-[url('assets/images/signup/julie-avatar.png')] bg-cover" />
              </div>
              <div className="flex flex-col items-start justify-center">
                <div className="text-caption font-bold text-green-150">
                  {DEFAULT_TESTIMONIAL_NAME}
                </div>
                <div className="text-caption text-green-150">
                  {DEFAULT_TESTIMONIAL_TITLE}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
