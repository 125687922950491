import { useMemo, useState, RefObject } from 'react';
import { DateTime } from 'luxon';
import {
  useNylasCalendarEventsQuery,
  useNylasPrimaryCalendarQuery,
  useDeleteNylasCalendarEventMutation,
  useUpdateNylasCalendarMutation,
  NylasCalendarEventDataFragment,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { useResources } from './useResources';
import { logger } from '../../../../../lib/logger';
import { useCalendars } from './useCalendars';
import { transformEventForCalendar } from '../utils/eventTransformers';

export const useCalendarEvents = (
  isNylasConnected: boolean,
  selectedCalendarIds?: string[],
  visibleRange?: {
    start: Date;
    end: Date;
  },
  calendarRef?: RefObject<any>,
) => {
  const [currentDate, setCurrentDate] = useState(DateTime.now());
  const { getResourceByEmail } = useResources();
  const { getCalendarByEmail } = useCalendars();

  const {
    data: nylasPrimaryCalendarData,
    loading: isLoadingNylasPrimaryCalendar,
    refetch: refetchNylasPrimaryCalendar,
  } = useNylasPrimaryCalendarQuery({
    skip: !isNylasConnected,
  });

  const primaryCalendarId = nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id;

  const {
    data: nylasCalendarEventsData,
    loading: isLoadingNylasCalendarEvents,
    refetch: refetchNylasCalendarEvents,
    networkStatus: calendarEventsNetworkStatus,
  } = useNylasCalendarEventsQuery({
    variables: {
      input: {
        calendarIds: selectedCalendarIds,
        startTime: visibleRange
          ? DateTime.fromJSDate(visibleRange.start)
          : currentDate.startOf('week'),
        endTime: visibleRange
          ? DateTime.fromJSDate(visibleRange.end)
          : currentDate.endOf('week'),
      },
    },
    skip: !isNylasConnected || !primaryCalendarId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const [updateNylasCalendar] = useUpdateNylasCalendarMutation();
  const [deleteNylasCalendarEvent, { loading: isDeletingEvent }] =
    useDeleteNylasCalendarEventMutation();

  const handleCalendarColorChange = async (
    calendarId: string,
    hexColor: string,
  ) => {
    try {
      const response = await updateNylasCalendar({
        variables: {
          input: { calendarId, hexColor },
        },
      });

      if (response.data?.updateNylasCalendar) {
        await refetchNylasPrimaryCalendar();
        customToast.success('Calendar color updated');
      }
    } catch (error) {
      customToast.error('Error updating calendar color');
    }
  };

  const handleDeleteEvent = async (event: NylasCalendarEventDataFragment) => {
    try {
      const response = await deleteNylasCalendarEvent({
        variables: {
          input: {
            eventId: event.id,
            calendarId: event.calendarId,
          },
        },
      });

      if (response.data?.deleteNylasCalendarEvent) {
        // Remove the event from the calendar immediately
        const calendarApi = calendarRef?.current?.getApi();
        if (calendarApi) {
          const calendarEvent = calendarApi.getEventById(event.id);
          if (calendarEvent) {
            calendarEvent.remove();
          }
        }

        customToast.success('Event deleted');
        return true;
      }
    } catch (error) {
      customToast.error('Error deleting event');
    }
    return false;
  };

  const transformEventsForCalendar = () => {
    logger.debug('📅 Before transformation', {
      rawEvents: nylasCalendarEventsData?.nylasCalendarEvents,
    });

    const transformedEvents =
      nylasCalendarEventsData?.nylasCalendarEvents
        ?.map((event) => {
          const resource = getResourceByEmail(event.calendarId);
          const calendar = getCalendarByEmail(event.calendarId);

          return transformEventForCalendar(event, {
            resourceId: resource?.id,
            calendarHexColor: resource?.hexColor || calendar?.hexColor,
          });
        })
        .filter(Boolean) || [];

    logger.debug('📅 After transformation', {
      transformedEvents,
    });

    return transformedEvents;
  };

  const events = useMemo(
    () => transformEventsForCalendar(),
    [
      nylasCalendarEventsData?.nylasCalendarEvents,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.id,
      nylasPrimaryCalendarData?.nylasPrimaryCalendar?.hexColor,
    ],
  );

  return {
    currentDate,
    setCurrentDate,
    events,
    isLoading:
      isLoadingNylasPrimaryCalendar ||
      isLoadingNylasCalendarEvents ||
      calendarEventsNetworkStatus === 4, // Refetching
    isDeletingEvent,
    primaryCalendar: nylasPrimaryCalendarData?.nylasPrimaryCalendar,
    handleCalendarColorChange,
    handleDeleteEvent,
    refetchEvents: refetchNylasCalendarEvents,
  };
};
