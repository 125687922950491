import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';
import { isClientUrl } from '../v2/lib/url';

/** Container for unauthenticated routes such as login and signup,
 * in which the user will be redirected to home if they are authenticated.
 *
 * Public routes can be placed elsewhere.
 * */
export default function UnauthedContainer() {
  const { auth0AccessToken } = useAuth();
  const location = useLocation();

  console.log('UnauthedContainer rendering with:', {
    path: location.pathname,
    auth0AccessToken: !!auth0AccessToken,
    isClientUrl: isClientUrl(),
    willRedirect: auth0AccessToken && !isClientUrl(),
  });

  if (auth0AccessToken && !isClientUrl()) {
    console.log('UnauthedContainer redirecting to home');
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}
