import { FC, useState } from 'react';
import Button from '../../../../components/Button';

interface PendingStateProps {
  cooldownTime: number;
  onVerifySender: () => Promise<void>;
  resendVerification: () => Promise<void>;
  cancelVerification: () => Promise<void>;
  emailSender: {
    fromEmail: string;
    fromName: string;
  };
  isVerifyLoading?: boolean;
}

export const PendingState: FC<PendingStateProps> = ({
  emailSender = { fromEmail: '', fromName: '' },
  cooldownTime,
  resendVerification,
  cancelVerification,
  onVerifySender,
  isVerifyLoading = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const withLoading = async (asyncFn: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await asyncFn();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mb-3 rounded-lg border border-neutral-75 bg-neutral-50 p-4">
      <div className="mb-2 text-caption font-medium text-neutral-150">
        Verification pending
      </div>
      <div className="mb-3 text-small-caption text-neutral-125">
        <ol className="list-decimal space-y-1 pl-5">
          <li>
            Check <strong>{emailSender?.fromEmail}</strong> for an email with
            subject
            <strong> "Please Verify Your Single Sender"</strong> from{' '}
            <strong>SendGrid</strong>.
          </li>
          <li>
            Right-click the button in that email and copy the URL (Copy Link
            Address).
          </li>
          <li>Send this URL to your account manager or our support team.</li>
          <li>
            Wait for confirmation before clicking the <strong>Verify</strong>{' '}
            button.
          </li>
        </ol>
      </div>
      <div className="flex gap-2">
        <Button
          size="small"
          title={isVerifyLoading ? 'Verifying...' : 'Verify'}
          theme="primary"
          noFocus
          onClick={() => withLoading(onVerifySender)}
          disabled={isLoading || isVerifyLoading}
        />

        <Button
          size="small"
          title="Resend"
          theme="secondary"
          noFocus
          onClick={() => withLoading(resendVerification)}
          disabled={isLoading || cooldownTime > 0}
        />

        <Button
          size="small"
          title="Cancel request"
          theme="destructive"
          noFocus
          onClick={() => withLoading(cancelVerification)}
          disabled={isLoading}
        />
      </div>
      {cooldownTime > 0 && (
        <div className="mt-2 text-small-caption text-neutral-125">
          Resend available in{' '}
          <span className="font-medium">{cooldownTime}</span> seconds.
        </div>
      )}
    </div>
  );
};
