import { useCallback, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { debounce } from 'lodash';

const DEBOUNCE_TIME_MS = 300;

export interface SearchOption {
  value: string;
  name: string;
  email: string;
  [key: string]: any; // Allow additional properties
}

interface SearchableComboboxProps<T extends SearchOption> {
  control: any;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  containerClassName?: string;
  searchFunction: (query: string) => Promise<T[]>;
  displayOption?: (option: T) => React.ReactNode; // Custom display renderer
  onSelect?: (option: T) => void; // Optional callback when an option is selected
  allowCustomValues?: boolean; // Control the "Add XXX" feature
}

export const SearchableCombobox = <T extends SearchOption>({
  control,
  name,
  label,
  placeholder = '',
  className,
  containerClassName,
  searchFunction,
  displayOption,
  onSelect,
  allowCustomValues = true, // Default to true for backward compatibility
}: SearchableComboboxProps<T>) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState<T[]>([]);

  const fetchOptions = async (newQuery: string, selectedOptions: T[]) => {
    const results = await searchFunction(newQuery);

    const filteredResults = results.filter(
      (option) =>
        !selectedOptions.some((selected) => selected.value === option.value),
    );
    setOptions(filteredResults);
  };

  const debouncedFetchOptions = useCallback(
    debounce(fetchOptions, DEBOUNCE_TIME_MS),
    [searchFunction],
  );

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: T[],
  ) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    if (newQuery.trim() !== '') {
      debouncedFetchOptions(newQuery, value);
    } else {
      setOptions([]);
    }
  };

  const shouldShowOptions = options.length > 0 || query.trim() !== '';

  return (
    <div className={classNames('group flex flex-col', containerClassName)}>
      {label && (
        <label className="mb-1 text-caption text-green-125" htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = [] } }) => (
          <Combobox
            value={value}
            onChange={(selected) => {
              if (JSON.stringify(selected) !== JSON.stringify(value)) {
                onChange(selected);
                if (onSelect && selected.length > value.length) {
                  const newOption = selected[selected.length - 1];
                  onSelect(newOption);
                }
              }
              setQuery('');
              setOptions([]);
            }}
            multiple
          >
            <div className="relative">
              <Combobox.Input
                onChange={(event) => handleInputChange(event, value)}
                displayValue={() => query}
                placeholder={placeholder}
                className={classNames(
                  className,
                  'rounded',
                  'block w-full',
                  'border-2 border-transparent text-body transition-colors',
                  'text-green-150 placeholder:text-neutral-125/75',
                  'focus:outline-none focus:ring-0',
                  'bg-neutral-50 py-4 px-4',
                  'selection:bg-secondary-25 focus:border-secondary-50',
                )}
              />
              {shouldShowOptions && (
                <Combobox.Options className="absolute z-10 mt-1 w-full rounded border border-neutral-100 bg-white">
                  {options.map((option, index) => (
                    <Combobox.Option
                      key={index}
                      value={option}
                      className={({ active }) =>
                        classNames(
                          'cursor-pointer p-2',
                          active
                            ? 'bg-neutral-25 text-green-150'
                            : 'text-green-150/80',
                        )
                      }
                    >
                      {displayOption ? (
                        displayOption(option)
                      ) : (
                        <div>
                          <div>{option.name}</div>
                          <div className="text-caption">{option.email}</div>
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                  {query && allowCustomValues && (
                    <Combobox.Option
                      key="custom-option"
                      value={{ name: query, email: query, value: query }}
                      className={({ active }) =>
                        classNames(
                          'cursor-pointer p-2',
                          active
                            ? 'bg-neutral-25 text-green-150'
                            : 'text-green-150/80',
                        )
                      }
                    >
                      Add "{query}"
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              )}
            </div>
          </Combobox>
        )}
      />
    </div>
  );
};
