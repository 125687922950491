import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePatientAuth } from '../../../contexts/PatientAuthContext';
import { useHomecomingLoader } from '../../hooks/useHomecomingLoader';
import {
  getClientLoginUrlWithRedirect,
  isValidRedirectUri,
} from '../../lib/url';
import { logger } from '../../../lib/logger';

const PatientLoginToken = () => {
  const { isAuthenticated } = usePatientAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const HomecomingLoader = useHomecomingLoader();

  const redirect = searchParams.get('redirect')!;

  useEffect(() => {
    const handleNavigation = () => {
      // Case 1: Invalid redirect URL
      if (!redirect || !isValidRedirectUri(redirect)) {
        logger.warn('Invalid redirect URL', { redirect });
        return isAuthenticated ? '/client/home' : '/login/client';
      }

      // Case 2: Valid redirect URL with authenticated user
      if (isAuthenticated) {
        logger.debug('User is authenticated, redirecting to:', redirect);
        return redirect;
      }

      // Case 3: Valid redirect URL with unauthenticated user
      const loginUrl = getClientLoginUrlWithRedirect(redirect);
      logger.debug(
        'User is not authenticated, redirecting to login:',
        loginUrl,
      );
      return loginUrl;
    };

    try {
      const destination = handleNavigation();
      navigate(destination, { replace: true });
    } catch (error) {
      logger.error('Error in PatientLoginToken:', error);
      navigate('/login/client', { replace: true });
    }
  }, [isAuthenticated, redirect, navigate]);

  return (
    <div className="flex h-full w-full flex-row items-center justify-center py-20">
      {HomecomingLoader}
    </div>
  );
};

export default PatientLoginToken;
