import { useEffect } from 'react';
import { useSessionsStore } from './useSessionStore';

export const useSessionsData = () => {
  const {
    sessions,
    pageInfo,
    totalCount,
    pendingCount,
    loading,
    error,
    fetchSessionsWithCount,
    currentStatus,
  } = useSessionsStore();

  // Fetch both sessions and pending count on mount and when status changes
  useEffect(() => {
    fetchSessionsWithCount();
  }, [fetchSessionsWithCount, currentStatus]);

  return {
    sessions,
    pageInfo,
    totalCount,
    pendingCount,
    loading,
    error,
    refetch: fetchSessionsWithCount,
  };
};
