import { FC } from 'react';
import Modal from '../../../../components/Modal';
import ClientSessionForm, {
  ClientSessionFormData,
} from '../forms/ClientSessionForm';
import {
  useUpdateClientSessionFormMutation,
  useFindClientSessionFormByTypeQuery,
  ClientSessionFormType,
  useUpdateClientSessionMutation,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { logger } from '../../../../../lib/logger';
import { SessionModalProps } from '../../types';
import EditClientSessionForm from '../forms/EditClientSessionForm';

const UpdateSessionModal: FC<SessionModalProps> = ({
  clientSession,
  onClose,
  onSuccess,
}) => {
  const [updateSession] = useUpdateClientSessionMutation();
  const [submitForm, { loading: isSubmitting }] =
    useUpdateClientSessionFormMutation();
  const { data: formData, loading: isLoading } =
    useFindClientSessionFormByTypeQuery({
      variables: {
        clientSessionId: clientSession.id,
        formType: ClientSessionFormType.Sb303V1,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });

  const handleSessionUpdate = async (formData: any) => {
    try {
      const response = await updateSession({
        variables: {
          input: {
            id: clientSession.id,
            ...formData,
          },
        },
      });

      if (response.data?.updateClientSession.success) {
        customToast.success('Session details updated.');
        onSuccess();
      }

      return {
        success: response.data?.updateClientSession.success ?? false,
        errors: response.data?.updateClientSession.errors,
      };
    } catch (error) {
      logger.error(error);
      return {
        success: false,
        errors: [
          { field: 'root', message: 'Failed to update session details' },
        ],
      };
    }
  };

  const handleSubmit = async (data: ClientSessionFormData) => {
    try {
      const response = await submitForm({
        variables: {
          input: {
            formId: formData?.findClientSessionFormByType.id,
            data: data,
          },
        },
      });

      if (response.data?.updateClientSessionForm.success) {
        customToast.success('Session updated successfully');
        onSuccess();
        onClose();
      }

      return {
        success: response.data?.updateClientSessionForm.success ?? false,
        errors: response.data?.updateClientSessionForm.errors,
      };
    } catch (error) {
      logger.error(error);
      return {
        success: false,
        errors: [{ field: 'root', message: 'Failed to update session' }],
      };
    }
  };

  if (isLoading) {
    return null; // Or show loading state
  }

  return (
    <Modal
      name="UpdateSession"
      isOpen={true}
      setClosed={onClose}
      width="medium"
    >
      <div className="p-6">
        <EditClientSessionForm
          session={clientSession}
          onSubmit={handleSessionUpdate}
        />
        <ClientSessionForm
          initialData={formData?.findClientSessionFormByType.data}
          onSubmit={handleSubmit}
          onCancel={onClose}
          isSubmitting={isSubmitting}
          mode="update"
        />
      </div>
    </Modal>
  );
};

export default UpdateSessionModal;
