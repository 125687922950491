import {
  CommonWhen,
  CommonWhenTimespan,
  CommonWhenDate,
  CommonWhenDatespan,
  CommonWhenType,
} from '../../../../../generated/graphql';
import { DateTime } from 'luxon';

// Type guards
export function isTimespanWhen(when: CommonWhen): when is CommonWhenTimespan {
  return when.type === CommonWhenType.Timespan;
}

export function isDateWhen(when: CommonWhen): when is CommonWhenDate {
  return when.type === CommonWhenType.Date;
}

export function isDatespanWhen(when: CommonWhen): when is CommonWhenDatespan {
  return when.type === CommonWhenType.Datespan;
}

export const whenToCalendarEvent = (when: CommonWhen, baseEventProps: any) => {
  if (isTimespanWhen(when)) {
    return {
      ...baseEventProps,
      start: when.startTime,
      end: when.endTime,
      allDay: false,
    };
  }

  if (isDateWhen(when)) {
    return {
      ...baseEventProps,
      start: when.date,
      end: when.date,
      allDay: true,
    };
  }

  if (isDatespanWhen(when)) {
    return {
      ...baseEventProps,
      start: when.startDate,
      end: when.endDate,
      allDay: true,
    };
  }

  return null;
};

export const calendarEventToWhen = (
  event: any,
  timezone: string,
): CommonWhen => {
  const isAllDay = event.allDay;

  if (isAllDay) {
    const startDate = event.start.toISOString().split('T')[0];
    const endDate = event.end?.toISOString().split('T')[0];

    if (endDate && startDate !== endDate) {
      return {
        type: CommonWhenType.Datespan,
        startDate,
        endDate,
      };
    } else {
      return {
        type: CommonWhenType.Date,
        date: startDate,
      };
    }
  }

  return {
    type: CommonWhenType.Timespan,
    startTime: event.start.toISOString(),
    endTime: event.end.toISOString(),
    startTimezone: timezone,
    endTimezone: timezone,
  };
};

export const formatWhen = (when: CommonWhen): string => {
  if (isDateWhen(when)) {
    return DateTime.fromISO(when.date).toLocaleString(DateTime.DATE_FULL);
  }

  if (isDatespanWhen(when)) {
    const start = DateTime.fromISO(when.startDate);
    const end = DateTime.fromISO(when.endDate);
    return `${start.toLocaleString(DateTime.DATE_FULL)} - ${end.toLocaleString(
      DateTime.DATE_FULL,
    )}`;
  }

  if (isTimespanWhen(when)) {
    const startTime = DateTime.fromISO(when.startTime);
    const endTime = DateTime.fromISO(when.endTime);
    return `${startTime.toLocaleString(
      DateTime.DATETIME_FULL,
    )} - ${endTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }

  return '';
};
