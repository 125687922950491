import { useBrandingDeleteVerifiedEmailSenderMutation } from '../../../../../../generated/graphql';
import { customToast } from '../../../../../components/ToastAlert/customToast';

export const useDeleteVerifiedEmailSender = (onSuccess: () => void) => {
  const [deleteVerifiedEmailSender, { loading }] =
    useBrandingDeleteVerifiedEmailSenderMutation();

  const deleteSender = async () => {
    try {
      const response = await deleteVerifiedEmailSender();

      const result = response.data?.brandingDeleteVerifiedEmailSender;

      if (result?.success) {
        customToast.success('Verified sender removed successfully.');
        onSuccess();
        return true;
      } else if (result?.errors && result.errors.length > 0) {
        // Handle any potential errors returned from the API
        result.errors.forEach((error) => {
          customToast.error(error.message);
        });
        return false;
      } else {
        customToast.error(
          'Failed to remove verified sender. Please try again.',
        );
        return false;
      }
    } catch (error: any) {
      const errorMessage =
        error.message || 'Failed to remove verified sender. Please try again.';
      customToast.error(errorMessage);
      return false;
    }
  };

  return {
    deleteSender,
    loading,
  };
};
