import { FC } from 'react';
import Modal from '../../../../components/Modal';
import ClientSessionForm, {
  AdverseReactionType,
  ClientSessionFormData,
} from '../forms/ClientSessionForm';
import {
  ClientSessionFormType,
  CommonWhenType,
  useSubmitClientSessionFormMutation,
  useUpdateClientSessionMutation,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { logger } from '../../../../../lib/logger';
import { SessionModalProps } from '../../types';
import EditClientSessionForm from '../forms/EditClientSessionForm';
import { formatDateForInput } from '../../../../lib/time';
import { formatTimeForInput } from '../../../../lib/time';

const ReviewSessionModal: FC<SessionModalProps> = ({
  clientSession,
  onClose,
  onSuccess,
}) => {
  const [createSession, { loading: isSubmitting }] =
    useSubmitClientSessionFormMutation();
  const [updateSession] = useUpdateClientSessionMutation();

  const handleSessionUpdate = async (formData: {
    id: string;
    title: string;
    description: string;
    when: {
      type: CommonWhenType;
      startTime: string;
      endTime: string;
    };
  }) => {
    try {
      const response = await updateSession({
        variables: {
          input: formData,
        },
      });

      if (response.data?.updateClientSession.success) {
        customToast.success('Session details updated.');
        onSuccess();
      }

      return {
        success: response.data?.updateClientSession.success ?? false,
        errors: response.data?.updateClientSession.errors,
      };
    } catch (error) {
      logger.error(error);
      return {
        success: false,
        errors: [
          { field: 'root', message: 'Failed to update session details' },
        ],
      };
    }
  };

  const handleSubmit = async (formData: ClientSessionFormData) => {
    try {
      const response = await createSession({
        variables: {
          input: {
            clientSessionId: clientSession.id,
            formType: ClientSessionFormType.Sb303V1,
            data: formData,
          },
        },
      });

      if (response.data?.submitClientSessionForm.success) {
        customToast.success('Session has been confirmed.');
        onSuccess();
        onClose();
      }

      return {
        success: response.data?.submitClientSessionForm.success ?? false,
        errors: response.data?.submitClientSessionForm.errors,
      };
    } catch (error) {
      logger.error(error);
      return {
        success: false,
        errors: [{ field: 'root', message: 'Failed to create session' }],
      };
    }
  };

  const initialFormData: ClientSessionFormData = {
    date: formatDateForInput(clientSession.startTime),
    startTime: formatTimeForInput(clientSession.startTime),
    endTime: formatTimeForInput(clientSession.endTime),
    isDosing: false,
    wasDenied: false,
    otherNotes: '',
    dosing: [
      {
        administeredAt: '',
        substances: [{ name: '', amount: '', unit: 'mg' }],
      },
    ],
    postSessionReactions: false,
    adverseBehavioralReactions: AdverseReactionType.NO,
    adverseMedicalReactions: AdverseReactionType.NO,
  };

  return (
    <Modal
      name="CreateSession"
      isOpen={true}
      setClosed={onClose}
      width="medium"
    >
      <div className="p-6">
        <EditClientSessionForm
          session={clientSession}
          onSubmit={handleSessionUpdate}
        />
        <ClientSessionForm
          initialData={initialFormData}
          onSubmit={handleSubmit}
          onCancel={onClose}
          isSubmitting={isSubmitting}
          mode="review"
        />
      </div>
    </Modal>
  );
};

export default ReviewSessionModal;
