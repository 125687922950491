import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Transition } from '@headlessui/react';

import { FeatureFlagProvider } from '../contexts/FeatureFlagContext';

import { defaultTransitionProps } from '../v2/lib/animation';

import PatientHeader from '../v2/components/Headers/PatientHeader';
import { usePatientAuth } from '../contexts/PatientAuthContext';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useHomecomingLoader } from '../v2/hooks/useHomecomingLoader';
import { getClientLoginUrlWithRedirect } from '../v2/lib/url';

const HIDE_HEADER_ROUTES = ['/client/intake'];
const UNAUTHED_ROUTES = ['/login-token', '/login/client/verify'];

function AuthedPatientContainer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { patientTokenPayload, authedPatient, refreshAuthedPatient } =
    usePatientAuth();

  const HomecomingLoader = useHomecomingLoader();

  // Add logging to see the current path and authentication state
  console.log('AuthedPatientContainer rendering with:', {
    path: location.pathname,
    patientTokenPayload: !!patientTokenPayload,
    authedPatient: !!authedPatient,
  });

  const loadInitialAuthedPatient = async () => {
    try {
      await refreshAuthedPatient();
    } catch (error) {
      // TODO: Test if this is necessary / works
      if (error && error.message !== 'Invalid bearer token') {
        // Don't show an error message for invalid token errors.
        navigate('/login/client');
        // logout();
      }
    }
  };

  useEffect(() => {
    if (patientTokenPayload && !authedPatient) {
      // This will occur upon a page refresh.
      loadInitialAuthedPatient();
    }
  }, [patientTokenPayload, authedPatient]);

  // Add logging before the UNAUTHED_ROUTES check
  console.log('Checking UNAUTHED_ROUTES:', {
    pathname: location.pathname,
    UNAUTHED_ROUTES,
    matchesAny: UNAUTHED_ROUTES.some((route) =>
      location.pathname.includes(route),
    ),
    patientTokenExists: !!patientTokenPayload,
  });

  // Special case here for unauthed routes like /login-token and /login/client/verify
  // Because these routes need to be accessible without authentication
  if (UNAUTHED_ROUTES.some((route) => location.pathname.includes(route))) {
    console.log('Matched UNAUTHED_ROUTE, returning <Outlet />');
    return <Outlet />;
  }

  if (!patientTokenPayload) {
    console.log(
      'No patient token payload, redirecting to login',
      patientTokenPayload,
    );

    const loginUrl = getClientLoginUrlWithRedirect(location);
    return <Navigate to={loginUrl} replace />;
  }

  const hideHeader = HIDE_HEADER_ROUTES.some((route) =>
    location.pathname.startsWith(route),
  );

  return (
    <FeatureFlagProvider>
      <Transition
        show={Boolean(authedPatient)}
        {...defaultTransitionProps}
        className="relative"
      >
        <>
          {!hideHeader && <PatientHeader />}
          <main
            className={classNames(!hideHeader && 'pt-[var(--top-nav-height)]')}
          >
            <Outlet />
          </main>
        </>
      </Transition>

      {!authedPatient && HomecomingLoader}
    </FeatureFlagProvider>
  );
}

export default AuthedPatientContainer;
