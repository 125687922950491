import { BOOK_A_CALL_URL } from './constants';
import { CommonAnalyticsEvent, trackProviderEvent } from '../../lib/analytics';

export const SUPPORT_EMAIL = 'support@homecoming.health';
export const SUPPORT_MAIL_TO_URL = `mailto:${SUPPORT_EMAIL}`;

export function withSearchParams(
  url: string,
  params: Record<string, string>,
): string {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });
  return urlObj.toString();
}

export const getShortLink = (link: string) => {
  try {
    return new URL(link).hostname.replace('www.', '');
  } catch (error) {
    return link;
  }
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank')?.focus();
};

export const openBookACallLink = () => {
  openInNewTab(BOOK_A_CALL_URL);
  trackProviderEvent(CommonAnalyticsEvent.ButtonClicked, {
    buttonName: 'Book a Call Button',
  });
};

export const isClientUrl = () => {
  const pathName = window.location.pathname;

  return (
    ['/client', '/login/client'].includes(pathName) ||
    pathName.startsWith('/client/') ||
    pathName.startsWith('/login/client/verify/')
  );
};

/**
 * Validates that a redirect URI is safe to use (relative path only)
 * @param uri The URI to validate
 * @returns boolean indicating if the URI is safe
 */
export const isValidRedirectUri = (uri: string): boolean => {
  if (!uri) return false;

  // Check if URI is absolute (has protocol or starts with //)
  if (/^(https?:)?\/\//i.test(uri)) return false;

  // Must start with / to be a valid relative path
  if (!uri.startsWith('/')) return false;

  // Optional: Allow only specific path prefixes
  // const allowedPrefixes = ['/client/', '/client', '/provider/', '/provider'];
  // return allowedPrefixes.some(prefix => uri.startsWith(prefix));

  return true;
};

/**
 * Encodes a path and optional query params for use as a redirect URI
 * @param path The path to encode
 * @param queryParams Optional query parameters
 * @returns Encoded URI string
 */
export const encodeRedirectUri = (
  path: string,
  queryParams?: Record<string, string>,
): string => {
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }

  // Add query params if provided
  if (queryParams && Object.keys(queryParams).length > 0) {
    const searchParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      searchParams.append(key, value);
    });
    path = `${path}?${searchParams.toString()}`;
  }

  return encodeURIComponent(path);
};

/**
 * Safely generates a login URL with redirect
 * @param currentPathOrLocation Current path or location object to redirect back to after login
 * @param queryParams Optional query parameters (only used if first param is string)
 * @returns Login URL with safe redirect
 */
export const getClientLoginUrlWithRedirect = (
  currentPathOrLocation: string | { pathname: string; search: string },
  queryParams?: Record<string, string>,
): string => {
  // Handle Location object case
  if (typeof currentPathOrLocation !== 'string') {
    const { pathname, search } = currentPathOrLocation;
    const searchParams = search
      ? Object.fromEntries(new URLSearchParams(search))
      : undefined;
    const redirectUri = encodeRedirectUri(pathname, searchParams);
    return `/login/client?redirectUri=${redirectUri}`;
  }

  // Handle string path case
  const redirectUri = encodeRedirectUri(currentPathOrLocation, queryParams);
  return `/login/client?redirectUri=${redirectUri}`;
};

/**
 * Gets a safe redirect URI from a query parameter
 * @param redirectUri The redirect URI from query parameter
 * @param defaultUri Default URI to use if redirectUri is invalid
 * @returns A safe URI to redirect to
 */
export const getSafeRedirectUri = (
  redirectUri: string | null,
  defaultUri = '/home',
): string => {
  if (!redirectUri || !isValidRedirectUri(redirectUri)) {
    return defaultUri;
  }
  return redirectUri;
};
