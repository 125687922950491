import { ApolloError } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  MePatientDataFragment,
  PatientLoginResponse,
  useMePatientLazyQuery,
  useAuthenticationLogoutMutation,
} from '../generated/graphql';

import {
  clearPatientTokenPayload,
  getPatientTokenPayload,
  storePatientTokenPayload,
} from '../lib/auth';
import SentryHelpers from '../lib/sentry';
import { customToast } from '../v2/components/ToastAlert/customToast';

interface PatientAuthContextType {
  patientTokenPayload: PatientLoginResponse | null;
  updateTokenPayload: (tokenPayload: PatientLoginResponse | null) => void;
  authedPatient: MePatientDataFragment | null;
  isAuthedPatientLoading: boolean;
  authedPatientError?: ApolloError;
  refreshAuthedPatient: () => Promise<MePatientDataFragment | null | undefined>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
}

const PatientAuthContext = React.createContext<PatientAuthContextType>(null!);

export const PatientAuthProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [patientTokenPayload, setPatientTokenPayload] =
    useState<PatientLoginResponse | null>(getPatientTokenPayload());

  const [authedPatient, setAuthedPatient] =
    useState<MePatientDataFragment | null>(null);

  const [
    mePatientQuery,
    { loading: isAuthedPatientLoading, error: authedPatientError },
  ] = useMePatientLazyQuery();

  const [authenticationLogout] = useAuthenticationLogoutMutation();

  const navigate = useNavigate();

  const updateTokenPayload = (
    tokenPayload: PatientLoginResponse | null,
  ): void => {
    if (tokenPayload) {
      storePatientTokenPayload(tokenPayload);
      setPatientTokenPayload(tokenPayload);
    } else {
      clearPatientTokenPayload();
      setPatientTokenPayload(null);
    }
  };

  const refreshAuthedPatient = useCallback(async () => {
    const response = await mePatientQuery();
    const authedPatient = response.data?.mePatient;

    setAuthedPatient(authedPatient ?? null);

    return authedPatient;
  }, [mePatientQuery]);

  const logout = useCallback(async () => {
    let logoutSuccessful = false;

    try {
      // Call the server-side logout endpoint
      const { data } = await authenticationLogout();

      if (data?.authenticationLogout.errors?.length) {
        console.error('Logout errors:', data.authenticationLogout.errors);
        customToast.error('An error occurred during logout');
      } else {
        customToast.success('You have been successfully logged out.');
      }

      // Mark as successful since we got a response
      logoutSuccessful = true;
    } catch (err) {
      console.error('Error during logout:', err);
      customToast.error('An error occurred during logout.');

      // If we can still determine this is not a network error, proceed with logout
      if (!(err instanceof ApolloError && err.networkError)) {
        logoutSuccessful = true;
      } else {
        customToast.error('Network error during logout. Please try again.');
      }
    } finally {
      // Proceed with client-side logout if the server request was successful
      // or if we had a non-network error
      if (logoutSuccessful) {
        // Clear client-side auth state
        updateTokenPayload(null);
        setAuthedPatient(null);

        // Clear Sentry user scope
        SentryHelpers.clearUserScope();

        // Redirect to client login page
        navigate('/login/client', { replace: true });
      }
    }
  }, [navigate, authenticationLogout]);

  return (
    <PatientAuthContext.Provider
      value={{
        patientTokenPayload,
        updateTokenPayload,
        authedPatient,
        isAuthedPatientLoading,
        authedPatientError,
        refreshAuthedPatient,
        logout,
        isAuthenticated: !!patientTokenPayload,
      }}
    >
      {children}
    </PatientAuthContext.Provider>
  );
};

export const usePatientAuth = () => {
  return React.useContext(PatientAuthContext);
};
