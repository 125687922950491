import { DateTime } from 'luxon';
import {
  useCreateNylasCalendarEventMutation,
  useUpdateNylasCalendarEventMutation,
} from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { CreateEventFormData } from '../components/types';
import { serializeEventTime } from '../utils/eventSerializers';
import { EventDropArg } from '@fullcalendar/core';
import { EventResizeDoneArg } from '@fullcalendar/interaction';

export const useEventMutation = () => {
  const [createEvent] = useCreateNylasCalendarEventMutation();
  const [updateEvent] = useUpdateNylasCalendarEventMutation();

  const handleCreateEvent = async (
    data: CreateEventFormData,
    calendarId?: string,
  ): Promise<{ success: boolean; event?: any }> => {
    try {
      const when = serializeEventTime(data);

      const response = await createEvent({
        variables: {
          input: {
            calendarId,
            data: {
              ...when,
              title: data.title,
              description: data.description,
              location: data.location,
              participants: data.participants,
              resources: data.resources,
            },
          },
        },
      });

      if (!response.data?.createNylasCalendarEvent) {
        throw new Error('Failed to create event');
      }

      customToast.success('Event created successfully');
      return {
        success: true,
        event: response.data.createNylasCalendarEvent,
      };
    } catch (error) {
      console.error('Error creating event:', error);
      customToast.error('Failed to create event');
      return { success: false };
    }
  };

  const handleUpdateEvent = async (
    calendarId: string,
    eventId: string,
    data: CreateEventFormData,
    onSuccess?: () => void,
    calendarApi?: any,
  ) => {
    try {
      const when = serializeEventTime(data);

      // Optimistically update the UI
      if (calendarApi) {
        const existingEvent = calendarApi.getEventById(eventId);
        if (existingEvent) {
          // Update the event properties
          existingEvent.setProp('title', data.title);

          // Update dates based on event type (all-day vs time-based)
          if (when.when) {
            if ('startTime' in when.when && 'endTime' in when.when) {
              // Time-based event
              existingEvent.setDates(
                new Date(when.when.startTime * 1000),
                new Date(when.when.endTime * 1000),
                { allDay: false },
              );
            } else if ('startDate' in when.when && 'endDate' in when.when) {
              // All-day event
              existingEvent.setDates(
                new Date(when.when.startDate),
                new Date(when.when.endDate),
                { allDay: true },
              );
            }
          }

          // Update other visible properties
          existingEvent.setExtendedProp('description', data.description);
          existingEvent.setExtendedProp('location', data.location);
        }
      }

      const response = await updateEvent({
        variables: {
          input: {
            calendarId,
            eventId,
            data: {
              ...when,
              title: data.title,
              description: data.description,
              location: data.location,
              participants: data.participants,
            },
          },
        },
      });

      if (!response.data?.updateNylasCalendarEvent) {
        throw new Error('Failed to update event');
      }

      customToast.success('Event updated successfully');
      onSuccess?.();
      return true;
    } catch (error) {
      // Revert optimistic update on error
      if (calendarApi) {
        calendarApi.refetchEvents();
      }
      console.error('Error updating event:', error);
      customToast.error('Failed to update event');
      return false;
    }
  };

  interface EventDragOrResizeArgs {
    info: EventDropArg | EventResizeDoneArg;
    calendarId?: string;
    onSuccess?: () => void;
    onError?: () => void;
  }

  const handleEventDragOrResize = async ({
    info,
    calendarId,
    onSuccess,
    onError,
  }: EventDragOrResizeArgs): Promise<boolean> => {
    const { event } = info;

    // Optimistically update the event immediately
    if (info.view.calendar) {
      const existingEvent = info.view.calendar.getEventById(event.id);
      if (existingEvent) {
        existingEvent.setDates(event.start, event.end, {
          allDay: event.allDay,
        });
      }
    }

    try {
      const eventData = {
        isAllDay: event.allDay,
        when: {
          startDate: event.startStr.split('T')[0],
          endDate: event.endStr.split('T')[0],
          startTime: DateTime.fromISO(event.startStr).toFormat('HH:mm'),
          endTime: DateTime.fromISO(event.endStr).toFormat('HH:mm'),
          startTimezone: event.extendedProps.originalEvent.when.startTimezone,
          endTimezone: event.extendedProps.originalEvent.when.endTimezone,
        },
      };

      const serialized = serializeEventTime(eventData);
      const response = await updateEvent({
        variables: {
          input: {
            calendarId,
            eventId: event.id,
            data: serialized,
          },
        },
      });

      if (!response.data?.updateNylasCalendarEvent) {
        throw new Error('Failed to update event');
      }

      customToast.success('Event updated successfully');
      onSuccess?.();

      return true;
    } catch (error) {
      // On error, revert to original position
      if (info.revert) info.revert();

      console.error('Error updating event:', error);
      customToast.error('Failed to update event');
      onError?.();
      return false;
    }
  };

  return { handleCreateEvent, handleUpdateEvent, handleEventDragOrResize };
};
