import React from 'react';
import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import { useDismissClientSessionsMutation } from '../../../../../generated/graphql';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { ClientSession } from '../../../../../generated/graphql';
import { logger } from '../../../../../lib/logger';
import { pluralize } from '../../../../lib/copy';

interface DismissModalProps {
  sessions: ClientSession[];
  onClose: () => void;
  onSuccess: () => void;
}

const DismissModal: React.FC<DismissModalProps> = ({
  sessions,
  onClose,
  onSuccess,
}) => {
  const [dismissClientSessions, { loading: isDismissingSessions }] =
    useDismissClientSessionsMutation();

  const handleConfirmDismiss = async () => {
    try {
      const sessionIds = sessions.map((session) => session.id);
      const result = await dismissClientSessions({
        variables: {
          ids: sessionIds,
        },
      });

      const response = result.data?.dismissClientSessions;

      if (!response?.success) {
        // Handle complete failure case
        const errorMessage =
          response?.errors?.[0]?.message ||
          `Failed to dismiss ${pluralize(
            sessions.length,
            'session',
            'sessions',
            false,
          )}`;

        logger.warn('Dismiss sessions mutation failed', {
          sessionIds,
          errors: response?.errors,
        });

        customToast.error(errorMessage);
        onClose();
        return;
      }

      // Handle partial or complete success
      const { summary } = response;
      const { processedCount, errorCount, skippedCount } = summary;

      if (errorCount > 0) {
        // Some sessions failed to dismiss
        customToast.warning(
          `${pluralize(
            processedCount,
            'session was',
            'sessions were',
          )} dismissed, ${errorCount} failed`,
        );
      } else if (skippedCount > 0) {
        // Some sessions were already dismissed
        customToast.success(
          `${pluralize(
            processedCount,
            'session was',
            'sessions were',
          )} dismissed, ${skippedCount} already dismissed`,
        );
      } else {
        // All sessions were successfully dismissed
        customToast.success(
          sessions.length === 1
            ? 'Session was dismissed'
            : `${processedCount} sessions were dismissed`,
        );
      }

      onSuccess();
      onClose();
    } catch (error) {
      logger.error('Error dismissing sessions:', error);
      customToast.error(
        `Failed to dismiss ${pluralize(
          sessions.length,
          'session',
          'sessions',
          false,
        )}`,
      );
      onClose();
    }
  };

  return (
    <Modal
      name="ConfirmDismiss"
      isOpen={true}
      setClosed={onClose}
      width="small"
    >
      <div className="px-10 py-8">
        <div className="flex flex-col items-center">
          <div className="mb-2 flex text-center font-serif text-subtitle-small text-green-150">
            Would you like to dismiss{' '}
            {sessions.length === 1
              ? 'this session'
              : `these ${sessions.length} sessions`}
            ?
          </div>
          <div className="text-center text-body text-green-125">
            {sessions.length === 1
              ? 'This session will be marked as dismissed and removed from your review queue.'
              : `These sessions will be marked as dismissed and removed from your review queue.`}
          </div>
        </div>
        <div className="mt-11 flex w-full flex-row items-center justify-center">
          <Button
            title="Cancel"
            theme="secondary"
            className="mr-5"
            onClick={onClose}
          />
          <Button
            title="Dismiss"
            theme="primary"
            onClick={handleConfirmDismiss}
            disabled={isDismissingSessions}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DismissModal;
