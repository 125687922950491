import { NylasCalendarEventDataFragment } from '../../../../../generated/graphql';
import { DEFAULT_CALENDAR_HEX_COLOR } from '../../../../lib/colors';
import { whenToCalendarEvent } from './whenHelpers';
import { logger } from '../../../../../lib/logger';

interface TransformEventOptions {
  resourceId?: string;
  calendarHexColor?: string;
  uniqueEventId?: string;
}

export const transformEventForCalendar = (
  event: NylasCalendarEventDataFragment,
  options: TransformEventOptions = {},
) => {
  const { resourceId, calendarHexColor, uniqueEventId } = options;

  logger.debug('🎯 Transforming event:', {
    eventId: event.id,
    title: event.title,
    resourceId,
    calendarHexColor,
  });

  const baseEventProps: any = {
    id: uniqueEventId || event.id,
    title: event.title || '',
    resourceId,
    backgroundColor: calendarHexColor || DEFAULT_CALENDAR_HEX_COLOR,
    // Enable FullCalendar editing
    editable: !event.readOnly,
    startEditable: !event.readOnly,
    durationEditable: !event.readOnly,
    /* Custom Properties */
    extendedProps: {
      originalEvent: event,
      calendarId: event.calendarId,
    },
  };

  const transformedEvent = whenToCalendarEvent(event.when, baseEventProps);

  logger.debug('🎯 Transformed event:', { transformedEvent });

  return transformedEvent;
};
