import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import { CogIcon, SparklesIcon } from '@heroicons/react/outline';
import { Transition as HistoryTransition } from 'history';

import {
  useForm,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form';

import {
  GiftIcon,
  CheckIcon,
  TrashIcon,
  PlusSmIcon,
  CalendarIcon,
  DuplicateIcon,
  ExternalLinkIcon,
  ArrowNarrowLeftIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';

import useBlocker from '../../hooks/useBlocker';
import usePaintScreen from '../../hooks/usePaintScreen';

import { useAuth } from '../../../contexts/AuthContext';

import CalendlyIcon from '../../svgs/CalendlyIcon';

import { ProviderActivityType } from '../../types/activity';

import {
  urlFromSlug,
  handleSeeMyPage,
  handleCopyMyPage,
} from '../../../lib/storefront';
import { resizeTextArea } from '../../lib/form';
import { ContentType } from '../../lib/followUp';
import { defaultTransitionProps } from '../../lib/animation';
import { isProviderUserOwnerOrAdminAuthorized } from '../../../lib/auth';

import {
  ServiceIcon,
  ActivityType,
  PatientAccessLevel,
  useActivityLazyQuery,
  ActivityDataFragment,
  useProviderServiceQuery,
  useCalendlyEventTypesQuery,
  useMeProviderProgramTagsQuery,
  useCreateProviderServiceMutation,
  useUpdateProviderServiceMutation,
  useArchiveProviderServiceMutation,
  useSearchProviderUsersLazyQuery,
} from '../../../generated/graphql';

import AddResourceModal, {
  AddResourceModalRef,
} from '../../components/Modals/AddResourceModal';

import Spinner from '../../svgs/Spinner';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import CalendlyLogo from '../../svgs/CalendlyLogo';
import IconButton from '../../components/IconButton';
import SelectMenu from '../../components/SelectMenu';
import InputGroup from '../../components/InputGroup';
import ArrowRightLong from '../../svgs/ArrowRightLong';
import RadioOption from '../../components/RadioOption';
import TextAreaGroup from '../../components/TextAreaGroup';
import ContentPreview from '../../components/ContentPreview';
import IntegrationCard from '../../components/IntegrationCard';
import { SERVICE_ICONS } from '../../components/ServiceCard/helpers';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
import PageContainer from '../../components/Containers/PageContainer';
import { customToast } from '../../components/ToastAlert/customToast';
import UnsavedChangesModal from '../../components/Modals/UnsavedChangesModal';
import ProgramTagManager from '../../components/ProgramTag/ProgramTagManager';
import ProviderActivitySlideover from '../../components/Library/ProviderActivitySlideover';

import {
  MAX_NAME_LENGTH,
  ServiceBuilderForm,
  MAX_DESCRIPTION_LENGTH,
  convertProviderUserToSearchOption,
} from './helpers';

import Section from './Section';
import ServiceIconItem from './ServiceIconItem';
import BodySectionLabel from './BodySectionLabel';
import { SearchableCombobox } from '../../components/SearchableCombobox';
import Avatar from '../../components/Avatar';

const ServiceBuilder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const providerServiceId = params.providerServiceId ?? '';
  const isCreatingNewService = !providerServiceId;
  const { authedProviderUser } = useAuth();
  const paintScreen = usePaintScreen();
  const providerPrimaryColor = authedProviderUser?.primaryColor;

  useEffect(() => {
    if (!isProviderUserOwnerOrAdminAuthorized(authedProviderUser)) {
      navigate('/', { replace: true });
    }
  }, [authedProviderUser]);

  // State to communicate that user is coming back from the form builder, having successfully
  // created a new form to attach, so we open up the intake form module and auto-attach it.
  const intakeFormAssessmentIdToAttach = (
    location.state as {
      intakeFormAssessmentIdToAttach?: string;
    }
  )?.intakeFormAssessmentIdToAttach;

  useEffect(() => {
    if (intakeFormAssessmentIdToAttach) {
      setValue('intakeFormAssessmentId', intakeFormAssessmentIdToAttach);
    }
  }, [intakeFormAssessmentIdToAttach]);

  const hasCalendlyIntegrated = Boolean(
    authedProviderUser?.provider?.calendlyIntegration,
  );

  const { data: providerServiceData, loading: providerServiceDataLoading } =
    useProviderServiceQuery({
      variables: { providerServiceId },
      skip: !providerServiceId,
    });

  const { data: calendlyEventTypeData, loading: calendlyEventTypesLoading } =
    useCalendlyEventTypesQuery({
      skip: !hasCalendlyIntegrated,
      onError: (error) => {
        customToast.error('Failed to get Calendly event types.');
      },
    });

  const calendlyEventTypes = calendlyEventTypeData?.calendlyEventTypes ?? [];

  const { data: providerTagsData, refetch: refetchProviderTags } =
    useMeProviderProgramTagsQuery();

  const [
    createProviderServiceMutation,
    { loading: createProviderServiceMutationLoading },
  ] = useCreateProviderServiceMutation();

  const [
    updateProviderServiceMutation,
    { loading: updateProviderServiceMutationLoading },
  ] = useUpdateProviderServiceMutation();

  const [
    archiveProviderServiceMutation,
    { loading: archiveProviderServiceMutationLoading },
  ] = useArchiveProviderServiceMutation();

  const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] =
    useState(false);

  const {
    watch,
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ServiceBuilderForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      serviceIcon: ServiceIcon.Circles,
      serviceIconColorUseCalendlyDefault: false,
      calendlyEventColor: null,
      slug: '',
      description: '',
      duration: 0,
      isPaid: false,
      price: 0,
      calendlyEventTypeUri: null,
      serviceTags: [{ name: 'Prospect' }],
      intakeFormAssessmentId: null,
      isEnabled: true,
      isShownOnStorefront: true,
      patientAccessLevel: PatientAccessLevel.All,
      practitioners: [],
    },
  });

  const showPage = paintScreen && !providerServiceDataLoading;
  const existingProviderService = providerServiceData?.providerService;

  const serviceUrl = urlFromSlug(existingProviderService?.slug);

  const hasUnsavedChanges = Boolean(isDirty);
  const [submitting, setSubmitting] = useState(false);
  const [navTransition, setNavTransition] = useState<HistoryTransition>();
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false);

  const triggerUnsavedChangesModal = Boolean(hasUnsavedChanges && !submitting);

  const blocker = useCallback(
    (navTransition: HistoryTransition) => {
      if (!isUnsavedChangesModalOpen) {
        setIsUnsavedChangesModalOpen(true);
        setNavTransition(navTransition);
      }
    },
    [triggerUnsavedChangesModal],
  );

  useBlocker(blocker, triggerUnsavedChangesModal);

  const watchName = watch('name');
  const watchDescription = watch('description');
  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const nameRegister = register('name', {
    required: { value: true, message: 'Please give your service a name' },
    maxLength: {
      value: MAX_NAME_LENGTH,
      message: `Please ensure the title is less than ${MAX_NAME_LENGTH} characters`,
    },
    pattern: {
      value: /[a-zA-Z]/,
      message: 'Name must contain at least one letter',
    },
    onBlur: () => setValue('name', watchName.trim()),
  });

  const { ref: descriptionRegisterRef, ...descriptionRegister } = register(
    'description',
    {
      maxLength: {
        value: MAX_DESCRIPTION_LENGTH,
        message: `Please ensure the description is less than ${MAX_DESCRIPTION_LENGTH} characters`,
      },
      pattern: {
        value: /[a-zA-Z]/,
        message: 'Description must contain at least one letter',
      },
      onChange: () => resizeTextArea(descriptionRef),
      onBlur: () => setValue('description', watchDescription.trim()),
    },
  );

  const watchIsEnabled = watch('isEnabled');
  const watchCalendlyEventTypeUri = watch('calendlyEventTypeUri');
  const watchServiceIconColorUseCalendlyDefault = watch(
    'serviceIconColorUseCalendlyDefault',
  );
  const watchCalendlyEventColor = watch('calendlyEventColor');

  const watchPrice = watch('price');
  const priceRegister = register('price', {
    valueAsNumber: true,
    min: { value: 0, message: 'Price cannot be negative' },
    validate: (value) =>
      Number.isInteger(value) || 'Price must be a whole number',
  });

  const durationRegister = register('duration', {
    valueAsNumber: true,
    min: { value: 0, message: 'Duration cannot be negative' },
    validate: (value) =>
      Number.isInteger(value) || 'Duration must be a whole number',
  });

  useEffect(() => {
    resizeTextArea(descriptionRef);
  }, [descriptionRegisterRef]);

  useEffect(() => {
    if (existingProviderService) {
      const {
        isEnabled,
        isShownOnStorefront,
        patientAccessLevel,
        slug,
        name,
        description,
        isPaid,
        serviceIcon,
        serviceIconColorUseCalendlyDefault,
        calendlyEventColor,
        price,
        durationMinutes,
        serviceTags,
        intakeFormAssessment,
        calendlyEventTypeUri,
        assignedProviderUsers,
      } = existingProviderService;

      setValue('isEnabled', isEnabled);
      setValue('isShownOnStorefront', isShownOnStorefront);
      setValue('patientAccessLevel', patientAccessLevel);
      setValue('slug', slug);
      setValue('name', name);
      setValue('serviceIcon', serviceIcon);
      setValue(
        'serviceIconColorUseCalendlyDefault',
        serviceIconColorUseCalendlyDefault,
      );
      setValue('calendlyEventColor', calendlyEventColor);
      setValue('description', description);
      setValue('isPaid', isPaid);
      setValue('price', price);
      setValue('duration', durationMinutes);
      setValue('intakeFormAssessmentId', intakeFormAssessment?.id);
      setValue('serviceTags', serviceTags);
      setValue('calendlyEventTypeUri', calendlyEventTypeUri);

      // Map assignedProviderUsers to practitioners field
      if (assignedProviderUsers && assignedProviderUsers.length > 0) {
        setValue(
          'practitioners',
          assignedProviderUsers.map((user) => ({
            value: user.id,
            name: user.name,
            email: user.email,
            practitioner: {
              id: user.id,
              name: user.name,
              email: user.email,
              profileImageUrl: user.profileImageMedia?.url,
            },
          })),
        );
      }
    }
  }, [existingProviderService, setValue]);

  const onSubmit: SubmitHandler<ServiceBuilderForm> = async (data) => {
    setSubmitting(true);

    try {
      const input = {
        isEnabled: data.isEnabled,
        isShownOnStorefront: !data.isEnabled ? false : data.isShownOnStorefront,
        patientAccessLevel: data.patientAccessLevel,
        name: data.name,
        serviceIcon: data.serviceIcon,
        serviceIconColorUseCalendlyDefault:
          data.serviceIconColorUseCalendlyDefault,
        calendlyEventColor: data.calendlyEventColor,
        description: data.description,
        isPaid: Boolean(watchPrice > 0),
        price: data.price,
        durationMinutes: data.duration,
        serviceTags: data.serviceTags.map((tag) => {
          const { name, id } = tag;
          return { name, id };
        }),
        intakeFormAssessmentId: data.intakeFormAssessmentId,
        calendlyEventTypeUri: data.calendlyEventTypeUri,
        assignedProviderUserIds: data.practitioners.map((p) => p.value),
      };

      if (!isCreatingNewService) {
        await updateProviderServiceMutation({
          variables: { input: { providerServiceId, ...input } },
          onCompleted: (data) => {
            if (data?.updateProviderService) {
              customToast.success(
                `Successfully updated ${data.updateProviderService.name}!`,
              );
            }
          },
        });
      } else {
        await createProviderServiceMutation({
          variables: { input },
          onCompleted: (data) => {
            if (data?.createProviderService) {
              customToast.success(
                `Successfully created ${data.createProviderService.name}!`,
              );
            }
          },
        });
      }
      handleBack();
    } catch (err) {
      console.error('Errors submitting:', err);
      customToast.error('Something went wrong - please try again later.');
      setSubmitting(false);
    }
  };

  const handleErrors: SubmitErrorHandler<ServiceBuilderForm> = (errors) => {
    console.error('Errors submitting:', errors);
  };

  const isLoading = providerServiceDataLoading || calendlyEventTypesLoading;
  const isSaving =
    createProviderServiceMutationLoading ||
    updateProviderServiceMutationLoading;

  const addResourceModalRef = useRef<AddResourceModalRef | null>(null);
  const [isAddResourceModalOpen, setIsAddResourceModalOpen] = useState(false);
  const [isProviderActivitySlideoverOpen, setIsProviderActivitySlideoverOpen] =
    useState(false);

  const [getActivityQuery, { data: activityData }] = useActivityLazyQuery();
  const selectedProviderActivity = activityData?.activity;

  const selectedProviderActivityAssessment = selectedProviderActivity as {
    __typename?: 'ActivityAssessment';
    assessment: { __typename?: 'Assessment'; name: string };
  };

  const formName = selectedProviderActivityAssessment?.assessment?.name;

  const watchIntakeFormAssessmentId = watch('intakeFormAssessmentId');

  useEffect(() => {
    if (watchIntakeFormAssessmentId) {
      getActivityQuery({
        variables: {
          activityId: watchIntakeFormAssessmentId,
          activityType: ActivityType.Assessment,
        },
      });
    }
  }, [watchIntakeFormAssessmentId]);

  const handleProviderActivitiesSelected = async (
    providerActivities: ActivityDataFragment[],
  ) => {
    const providerActivity = providerActivities?.[0];
    if (
      !providerActivity ||
      providerActivity.__typename !== ProviderActivityType.Assessment
    ) {
      return;
    }

    setValue('intakeFormAssessmentId', providerActivity?.id);
  };

  const handleDeleteService = async () => {
    try {
      await archiveProviderServiceMutation({
        variables: { providerServiceId },
      });
    } catch (err) {
      customToast.error('Something went wrong - please try again later.');
    } finally {
      navigate('/your-page');
    }
  };

  const [isCopyingLink, setIsCopyingLink] = useState(false);

  const handleBack = () => {
    navigate(intakeFormAssessmentIdToAttach ? -3 : -1);
  };

  const [searchProviderUsers] = useSearchProviderUsersLazyQuery();

  const handleSearchPractitioners = async (query: string) => {
    try {
      const { data } = await searchProviderUsers({
        variables: { input: { searchTerm: query } },
      });

      // Get current practitioners to filter them out
      const currentPractitioners = getValues('practitioners') || [];
      const currentPractitionerIds = currentPractitioners.map((p) => p.id);

      // Convert to SearchOption format with practitioner data and filter out already selected practitioners
      return (data?.searchProviderUsers?.providerUsers || [])
        .filter((user) => !currentPractitionerIds.includes(user.id))
        .map(convertProviderUserToSearchOption);
    } catch (error) {
      console.error('Error searching practitioners:', error);
      return [];
    }
  };

  return (
    <div className="relative scroll-smooth">
      <Transition show={showPage} {...defaultTransitionProps}>
        <div className="t-16 fixed z-10 flex w-full flex-row items-center justify-between border-b border-neutral-50 bg-white py-3 px-20">
          <div className="w-[350px]">
            <IconButton
              IconComponent={ArrowNarrowLeftIcon}
              iconClassName="text-secondary-100 w-5"
              aria-label="Back"
              className="rounded-xl px-3 py-2 ring-0"
              onClick={handleBack}
            >
              <div className="small-caption ml-2 font-bold text-secondary-100">
                Back
              </div>
            </IconButton>
          </div>
          <span className="text-category font-medium text-green-150 line-clamp-1">
            {Boolean(watchName) ? watchName : 'Untitled service'}
          </span>
          <div className="flex w-[350px] items-center justify-end">
            {isSaving && <Spinner className="mr-3 h-5 w-5 text-neutral-125" />}
            {serviceUrl && (
              <>
                <Button
                  title="Open page"
                  IconComponent={ExternalLinkIcon}
                  iconPosition="left"
                  theme="secondary-white"
                  size="small"
                  className="mr-0.5 focus:ring-0"
                  disabled={!watchIsEnabled}
                  onClick={() => handleSeeMyPage(serviceUrl)}
                  circular
                />
                <Button
                  title="Copy link"
                  IconComponent={isCopyingLink ? CheckIcon : DuplicateIcon}
                  iconClassName={isCopyingLink && 'text-neutral-150'}
                  iconPosition="left"
                  size="small"
                  theme="secondary-white"
                  className="mr-3 focus:ring-0"
                  disabled={!watchIsEnabled}
                  onClick={() => {
                    setIsCopyingLink(true);
                    handleCopyMyPage(serviceUrl);
                    setTimeout(() => {
                      setIsCopyingLink(false);
                    }, 3000);
                  }}
                  circular
                />
              </>
            )}
            <Button
              title="Save"
              size="small"
              className="px-6 py-1.5"
              onClick={handleSubmit(onSubmit, handleErrors)}
              disabled={isSaving || isLoading}
            />
          </div>
        </div>
      </Transition>
      <PageContainer
        noPadding
        containerClassName="bg-white min-h-[calc(100vh-48px)] h-auto items-start pl-24 pt-24 pb-16"
        loading={!showPage}
      >
        <Transition
          beforeEnter={() => {
            window.scrollTo(0, 0);
          }}
          show={showPage}
          {...defaultTransitionProps}
        >
          {/* Main Content */}
          {/* Scrollable Content */}
          <div>
            <div className="max-w-[1000px] space-y-12">
              <Section title="Details" IconComponent={GiftIcon}>
                <div className="flex flex-row items-start justify-start">
                  <Controller
                    name="serviceIcon"
                    control={control}
                    defaultValue={undefined}
                    rules={{
                      required: {
                        value: true,
                        message: 'Choose an icon',
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <SelectMenu
                        label="Icon"
                        buttonClassName="max-h-12"
                        onChange={(selectedOption: { icon: ServiceIcon }) =>
                          onChange(selectedOption.icon)
                        }
                        fieldValue={
                          value
                            ? {
                                icon: value,
                                isCalendlyDefaultColor:
                                  watchServiceIconColorUseCalendlyDefault,
                                color: watchCalendlyEventColor,
                                providerPrimaryColor,
                              }
                            : null
                        }
                        fieldOptions={SERVICE_ICONS.map((icon) => ({
                          icon,
                          isCalendlyDefaultColor:
                            watchServiceIconColorUseCalendlyDefault,
                          color: watchCalendlyEventColor,
                          providerPrimaryColor,
                        }))}
                        SelectOptionComponent={ServiceIconItem}
                      />
                    )}
                  />
                  <InputGroup
                    required
                    label="Title"
                    useNaturalLettering
                    type="text"
                    inputSize="small"
                    errorMessage={errors.name?.message}
                    characterCounter
                    containerClassName="w-full ml-6"
                    currentLengthValue={watchName?.length}
                    maxLengthValue={MAX_NAME_LENGTH}
                    {...nameRegister}
                  />
                </div>
                <TextAreaGroup
                  label="Description"
                  value={watchDescription}
                  errorMessage={errors.description?.message}
                  characterCounter
                  currentLengthValue={watchDescription?.length}
                  maxLengthValue={MAX_DESCRIPTION_LENGTH}
                  {...descriptionRegister}
                  ref={(event) => {
                    descriptionRegisterRef(event);
                    descriptionRef.current = event;
                  }}
                />

                <div className="flex h-[72px] flex-row justify-start">
                  <InputGroup
                    type="number"
                    label="Duration (minutes)"
                    inputSize="small"
                    readOnly={Boolean(watchCalendlyEventTypeUri)}
                    readOnlyErrorText="This field cannot be changed if a Calendly event is selected."
                    className="w-[150px]"
                    containerClassName="mr-6"
                    useNaturalLettering
                    errorMessage={errors.duration?.message}
                    {...durationRegister}
                  />
                  <InputGroup
                    type="number"
                    label="Price ($)"
                    inputSize="small"
                    className="w-[90px]"
                    useNaturalLettering
                    errorMessage={errors.price?.message}
                    {...priceRegister}
                  />
                </div>
              </Section>

              {/* Payment & Booking Section */}
              <Section
                title="Calendly event"
                IconComponent={CalendarIcon}
                SectionDetail={
                  <div className="mt-4 flex flex-col items-start justify-start space-y-2 text-secondary-125">
                    <div className="text-caption">How to...</div>
                    <a
                      className="cursor-pointer text-caption text-blue-100 hover:underline"
                      href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=utmnja6ezz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Setup events in Calendly
                    </a>
                    <div className="flex flex-col items-start justify-start space-y-1">
                      <div className="mt-4 text-caption">
                        Accept payments in Calendly
                      </div>
                      <a
                        className="cursor-pointer text-caption text-blue-100 hover:underline"
                        href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=licnc5y7gt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        via Stripe
                      </a>
                      <a
                        className="cursor-pointer text-caption text-blue-100 hover:underline"
                        href="https://help.calendly.com/hc/en-us/p/video-tutorials?wchannelid=zs2tmj7w77&wmediaid=4uyfnxvgld"
                        target="_blank"
                        rel="noreferrer"
                      >
                        via PayPal
                      </a>
                    </div>
                  </div>
                }
              >
                <div className="">
                  {!hasCalendlyIntegrated ? (
                    <IntegrationCard
                      logo={<CalendlyLogo className="h-8" />}
                      name="Calendly"
                      description="Sync your calendar with Homecoming and allow clients to schedule sessions."
                      isConnected={hasCalendlyIntegrated}
                      integrationPath="/integrations/calendly"
                    />
                  ) : (
                    <>
                      <BodySectionLabel
                        className="mb-4"
                        text="Select a Calendly event for automated scheduling and payments"
                      />
                      <div className="space-y-5">
                        {calendlyEventTypesLoading ? (
                          <Spinner />
                        ) : calendlyEventTypes.length === 0 ? (
                          <Alert
                            level="warning"
                            message="No event types found"
                          />
                        ) : (
                          <form>
                            <Controller
                              control={control}
                              name="calendlyEventTypeUri"
                              render={({ field }) => (
                                <div className="space-y-6">
                                  <RadioOption
                                    id="eventType_none"
                                    value={null}
                                    name={field.name}
                                    onChange={async (e) => {
                                      setValue('calendlyEventTypeUri', null);
                                      setValue(
                                        'serviceIconColorUseCalendlyDefault',
                                        false,
                                      );
                                      setValue('calendlyEventColor', null);
                                      setValue('name', '');
                                      setValue('duration', 0);
                                    }}
                                    onBlur={field.onBlur}
                                    checked={field.value === null}
                                    labelContent={
                                      <div className="flex items-center">
                                        <span className="mx-3 inline-block h-4 w-4 rounded-full bg-neutral-110 text-secondary-125" />
                                        No event
                                      </div>
                                    }
                                  />
                                  {Boolean(calendlyEventTypes.length) && (
                                    <div className="w-full border-b border-neutral-75"></div>
                                  )}
                                  {calendlyEventTypes
                                    .sort((a, b) => a.duration - b.duration)
                                    .map((eventType, index) => (
                                      <RadioOption
                                        key={`calendlyEventType_${index}`}
                                        id={`eventType_${eventType.name}`}
                                        value={eventType.calendlyEventTypeUri}
                                        name={field.name}
                                        onChange={async (e) => {
                                          field.onChange(e);
                                          setValue('name', eventType.name);
                                          setValue(
                                            'duration',
                                            eventType.duration,
                                          );
                                          setValue(
                                            'calendlyEventColor',
                                            eventType.color,
                                          );
                                          setValue(
                                            'serviceIconColorUseCalendlyDefault',
                                            true,
                                          );
                                        }}
                                        onBlur={field.onBlur}
                                        checked={
                                          field.value ===
                                          eventType.calendlyEventTypeUri
                                        }
                                        labelContent={
                                          <div className="flex items-center">
                                            <span
                                              className="mx-3 inline-block h-4 w-4 rounded-full"
                                              style={{
                                                backgroundColor:
                                                  eventType.color,
                                              }}
                                            />
                                            <div className="flex flex-row items-center justify-start">
                                              <div className="text-caption">
                                                {eventType.name}
                                              </div>
                                              <div className="ml-3 mt-0.5 text-small-caption text-neutral-125">
                                                {eventType.duration} minutes
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      />
                                    ))}
                                </div>
                              )}
                            />
                          </form>
                        )}
                      </div>

                      <BodySectionLabel
                        text="Set up payments or add a new event in Calendly"
                        className="mt-8"
                      />
                      <Button
                        title="Calendly settings"
                        size="small"
                        theme="secondary"
                        iconPosition="left"
                        onClick={() =>
                          window.open(
                            'https://calendly.com/event_types/user/me',
                            '_blank',
                          )
                        }
                        IconComponent={CalendlyIcon}
                      />
                    </>
                  )}
                </div>
              </Section>

              <Section title="Automations" IconComponent={SparklesIcon}>
                <div className="space-y-8">
                  <div>
                    <BodySectionLabel text="Tag clients who book this service" />
                    <Controller
                      name="serviceTags"
                      control={control}
                      render={({ field }) => (
                        <ProgramTagManager
                          selectedProgramTags={field.value}
                          onTagsChange={async (tags) => field.onChange(tags)}
                          availableProgramTags={
                            providerTagsData?.meProvider?.provider
                              ?.programTags ?? []
                          }
                          refreshAvailableProgramTags={refetchProviderTags}
                          minimumProgramTagCount={1}
                          maximumProgramTagCount={5}
                          className="min-w-[220px]"
                          alwaysIncludeAddTagButton
                          wrapTags
                        />
                      )}
                    />
                  </div>

                  <div>
                    <BodySectionLabel text="Add an intake form" />
                    <div className="space-y-6">
                      {!watchIntakeFormAssessmentId ? (
                        <div className="flex gap-4">
                          <Button
                            title="Add existing intake form"
                            theme="secondary"
                            iconPosition="left"
                            size="small"
                            IconComponent={PlusSmIcon}
                            onClick={() => setIsAddResourceModalOpen(true)}
                          />
                          <Button
                            title="Create a new intake form"
                            size="small"
                            theme="secondary-white"
                            IconComponent={ArrowRightLong}
                            className="group text-secondary-125 hover:bg-white hover:text-secondary-100"
                            iconClassName="opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-secondary-100"
                            onClick={() =>
                              navigate('/library/forms/new', {
                                state: {
                                  fromPage: 'services/new',
                                },
                              })
                            }
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row items-center space-x-10">
                          <ContentPreview
                            className="max-w-[500px] border border-neutral-75 !p-4 shadow-none"
                            contentType={ContentType.Form}
                            title={formName}
                            onClick={() =>
                              setIsProviderActivitySlideoverOpen(true)
                            }
                            onClickCloseX={() =>
                              setValue('intakeFormAssessmentId', null)
                            }
                          />
                          <Button
                            title="Preview"
                            theme="secondary-white"
                            className="group hover:bg-white"
                            iconClassName="opacity-0 group-hover:opacity-100"
                            IconComponent={ArrowRightLong}
                            noBackground
                            noOutline
                            onClick={() =>
                              setIsProviderActivitySlideoverOpen(true)
                            }
                          />
                        </div>
                      )}

                      <div className="flex items-start text-secondary-125 md:w-full md:max-w-[500px]">
                        <InformationCircleIcon className="mr-2 mt-0.5 h-4 w-4 flex-shrink-0" />
                        <div className="text-caption">
                          Homecoming collects{' '}
                          <span className="font-medium">
                            full names and emails
                          </span>{' '}
                          directly from your prospective clients, so you can
                          skip these details in your intake form.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <BodySectionLabel text="Assign to practitioners" />
                    <div className="space-y-4">
                      <Controller
                        name="practitioners"
                        control={control}
                        render={({ field }) => (
                          <div className="space-y-4">
                            <SearchableCombobox
                              control={control}
                              name="practitioners"
                              placeholder="Search practitioners..."
                              allowCustomValues={false}
                              searchFunction={handleSearchPractitioners}
                              className="w-full max-w-[500px]"
                              displayOption={(option) => (
                                <div
                                  key={`option-${option.value}`}
                                  className="flex items-center gap-2"
                                >
                                  <Avatar
                                    imageUrl={
                                      option.practitioner.profileImageUrl
                                    }
                                    name={option.name}
                                    size="small"
                                    className="h-6 w-6"
                                  />
                                  <div>
                                    <div>{option.name}</div>
                                    <div className="text-caption text-neutral-125">
                                      {option.email}
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                            <div className="flex flex-wrap gap-2">
                              {field.value?.map((practitioner) => (
                                <div
                                  key={`practitioner-${practitioner.value}`}
                                  className="flex items-center gap-2 rounded-full bg-neutral-50 py-1 pl-1 pr-3"
                                >
                                  <Avatar
                                    imageUrl={
                                      practitioner.practitioner.profileImageUrl
                                    }
                                    name={practitioner.name}
                                    size="medium"
                                    className="h-6 w-6"
                                  />
                                  <div className="flex flex-col">
                                    <span className="text-caption leading-tight text-secondary-125">
                                      {practitioner.name}
                                    </span>
                                    <span className="text-small-caption leading-tight text-neutral-125">
                                      {practitioner.email}
                                    </span>
                                  </div>
                                  <Button
                                    IconComponent={XIcon}
                                    theme="none"
                                    size="small"
                                    noBackground
                                    noOutline
                                    circular
                                    className="ml-1 text-neutral-125 hover:text-secondary-100"
                                    onClick={() => {
                                      const newValue = field.value.filter(
                                        (p) => p.value !== practitioner.value,
                                      );
                                      field.onChange(newValue);
                                    }}
                                    aria-label={`Remove ${practitioner.name}`}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      />
                      <div className="flex items-start text-secondary-125 md:w-full md:max-w-[500px]">
                        <InformationCircleIcon className="mr-2 mt-0.5 h-4 w-4 flex-shrink-0" />
                        <div className="text-caption">
                          Clients who book this service will be automatically
                          assigned to these practitioners, whether they're new
                          or existing clients.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section>

              <Section title="Settings" IconComponent={CogIcon}>
                <div className="flex flex-row items-start justify-start gap-x-12">
                  <div>
                    <BodySectionLabel text="Availability" />
                    <Controller
                      name="patientAccessLevel"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="space-y-4">
                          <Checkbox
                            id="patientAccessLevel-all"
                            type="radio"
                            value={PatientAccessLevel.All}
                            checked={
                              watchIsEnabled && value === PatientAccessLevel.All
                            }
                            onChange={() => {
                              onChange(PatientAccessLevel.All);
                              setValue('isEnabled', true);
                            }}
                            labelContent={
                              <div className="ml-2 text-caption">
                                <span className="font-medium">All clients</span>{' '}
                                may book this service
                              </div>
                            }
                          />
                          <Checkbox
                            id="patientAccessLevel-current"
                            type="radio"
                            value={PatientAccessLevel.Current}
                            checked={
                              watchIsEnabled &&
                              value === PatientAccessLevel.Current
                            }
                            onChange={() => {
                              onChange(PatientAccessLevel.Current);
                              setValue('isEnabled', true);
                            }}
                            labelContent={
                              <div className="ml-2 text-caption">
                                Only{' '}
                                <span className="font-medium">
                                  current clients
                                </span>{' '}
                                may book this service
                              </div>
                            }
                          />
                          <Checkbox
                            id="patientAccessLevel-new"
                            type="radio"
                            value={PatientAccessLevel.New}
                            checked={
                              watchIsEnabled && value === PatientAccessLevel.New
                            }
                            onChange={() => {
                              onChange(PatientAccessLevel.New);
                              setValue('isEnabled', true);
                            }}
                            labelContent={
                              <div className="ml-2 text-caption">
                                Only{' '}
                                <span className="font-medium">new clients</span>{' '}
                                may book this service
                              </div>
                            }
                          />
                        </div>
                      )}
                    />
                    {existingProviderService && (
                      <Controller
                        name="isEnabled"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            id="isEnabled"
                            checked={!field.value}
                            type="radio"
                            onChange={(e) => field.onChange(!e.target.checked)}
                            className="mt-4"
                            labelContent={
                              <div className="ml-2 text-caption">
                                Disable this service
                              </div>
                            }
                          />
                        )}
                      />
                    )}
                  </div>

                  <div>
                    <BodySectionLabel text="Visibility" />
                    <Controller
                      name="isShownOnStorefront"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className="space-y-4">
                          <Checkbox
                            id="isShownOnStorefront-true"
                            type="radio"
                            checked={value === true}
                            onChange={() => onChange(true)}
                            labelContent={
                              <div className="ml-2 text-caption">
                                Visible on{' '}
                                <span className="font-medium">your page</span>
                              </div>
                            }
                          />
                          <Checkbox
                            id="isShownOnStorefront-false"
                            type="radio"
                            checked={value === false}
                            onChange={() => onChange(false)}
                            labelContent={
                              <div className="ml-2 text-caption">
                                Private link only
                              </div>
                            }
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
              </Section>
              {existingProviderService && (
                <Section>
                  <Button
                    title="Delete service"
                    theme="destructive"
                    iconPosition="left"
                    IconComponent={TrashIcon}
                    size="small"
                    onClick={() => setIsDeleteServiceModalOpen(true)}
                  />
                </Section>
              )}
            </div>
          </div>
        </Transition>
      </PageContainer>
      <UnsavedChangesModal
        isModalOpen={isUnsavedChangesModalOpen}
        setClosed={() => setIsUnsavedChangesModalOpen(false)}
        onConfirm={() => navTransition?.retry()}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteServiceModalOpen}
        setClosed={() => setIsDeleteServiceModalOpen(false)}
        title={`Delete ${watchName}`}
        fetching={archiveProviderServiceMutationLoading}
        actionButtonTitle="Delete"
        performDelete={handleDeleteService}
      />
      <AddResourceModal
        isModalOpen={isAddResourceModalOpen}
        setClosed={() => setIsAddResourceModalOpen(false)}
        contentTypeFilterLock={ContentType.Form}
        restrictSingleSelection
        onAddProviderActivities={handleProviderActivitiesSelected}
        ref={addResourceModalRef}
        ignoreMultiSignatureForms
        resourceName="form"
      />
      <ProviderActivitySlideover
        isOpen={isProviderActivitySlideoverOpen}
        onClose={() => setIsProviderActivitySlideoverOpen(false)}
        selectedProviderActivity={selectedProviderActivity}
        followUpMode
      />
    </div>
  );
};

export default ServiceBuilder;
