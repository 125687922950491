import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAddNylasIntegrationMutation } from '../../../../../generated/graphql';
import Loading from '../../../Loading';
import { useEffect } from 'react';
import { useAuth } from '../../../../../contexts/AuthContext';
import {
  ProviderAnalyticsEvent,
  trackProviderEvent,
} from '../../../../../lib/analytics';
import { customToast } from '../../../../components/ToastAlert/customToast';
import { SUPPORT_EMAIL } from '../../../../lib/url';
import { useCalendarViewStore } from '../../../Schedule/CalendarV2/stores/useCalendarViewStore';

const NylasIntegrationCallback: React.FC = () => {
  const navigate = useNavigate();
  const { refreshAuthedProviderUser } = useAuth();

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [addNylasIntegrationMutation, { loading: addNylasIntegrationLoading }] =
    useAddNylasIntegrationMutation();

  const resetCalendarViewStore = useCalendarViewStore((state) => state.reset);

  const handleNylasCallback = async (code: string) => {
    try {
      const response = await addNylasIntegrationMutation({
        variables: {
          code,
        },
      });

      if (response.data) {
        resetCalendarViewStore();
        await refreshAuthedProviderUser();
        // Add a small delay before navigation to ensure state is updated for calendar page
        setTimeout(() => {
          navigate('/calendar');
          window.location.reload();
        }, 1000);
      } else {
        throw new Error('Server error');
      }
    } catch (error) {
      console.error(error);
      trackProviderEvent(
        ProviderAnalyticsEvent.AddNylasIntegrationAttemptErrored,
      );
      customToast.error(
        `There was an error connecting your calendar. Please try again or contact support at ${SUPPORT_EMAIL}.`,
      );
    }
  };

  useEffect(() => {
    if (!code || addNylasIntegrationLoading) return;

    handleNylasCallback(code);
  }, [code]);

  return <Loading />;
};

export default NylasIntegrationCallback;
