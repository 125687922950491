import { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Button from '../../../components/Button';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '../../../components/InputOTP';
import Checkbox from '../../../components/Checkbox';

export interface CodeFormData {
  code: string;
  rememberMe: boolean;
}

interface CodeVerificationFormProps {
  onSubmit: (data: CodeFormData) => Promise<void>;
  isSubmitting: boolean;
  maskedEmail: string;
  onBack: () => void;
  onResend?: () => void;
  isResending?: boolean;
}

const CodeVerificationForm: FC<CodeVerificationFormProps> = ({
  onSubmit,
  isSubmitting,
  maskedEmail,
  onBack,
  onResend,
  isResending = false,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CodeFormData>({
    mode: 'onSubmit',
    defaultValues: {
      rememberMe: true,
      code: '',
    },
  });

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full">
        <Controller
          name="code"
          control={control}
          rules={{
            required: 'Verification code is required',
            pattern: {
              value: /^\d{6}$/,
              message: 'Please enter a valid 6-digit code',
            },
          }}
          render={({ field: { onChange, value } }) => (
            <div className="mb-2">
              <InputOTP
                maxLength={6}
                value={value}
                onChange={onChange}
                containerClassName="justify-center"
                pattern="^[0-9]*$"
              >
                <InputOTPGroup>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <InputOTPSlot
                      key={index}
                      index={index}
                      className="text-lg h-14 w-14"
                    />
                  ))}
                </InputOTPGroup>
              </InputOTP>
              {errors.code && (
                <div className="mt-1 pt-2 text-center text-caption text-red-100">
                  {errors.code.message}
                </div>
              )}
            </div>
          )}
        />
      </div>

      <div className="mt-4 flex items-center justify-center">
        <Checkbox
          id="rememberMe"
          labelContent="Remember me on this device"
          className="flex items-center"
          checkboxClassName="h-4 w-4 border-gray-300 text-green-150 focus:ring-green-150"
          {...register('rememberMe')}
        />
      </div>

      <Button
        title="Verify"
        className="mx-auto my-6 w-full md:mt-8"
        type="submit"
        disabled={isSubmitting}
      />

      <div className="flex flex-col items-center justify-center gap-3 text-center">
        {onResend && (
          <Button
            title="Resend code"
            theme="secondary-white"
            size="small"
            type="button"
            disabled={isResending}
            onClick={onResend}
            className="text-green-150"
            noBackground
          />
        )}
        <Button
          title="Back to email"
          theme="secondary"
          size="small"
          type="button"
          onClick={onBack}
          noOutline
          noBackground
        />
      </div>
    </form>
  );
};

export default CodeVerificationForm;
