import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import EmailForm, { EmailFormData } from './EmailForm';
import { useAuthenticationRequestVerificationCodeMutation } from '../../../../generated/graphql';
import { customToast } from '../../../components/ToastAlert/customToast';
import LoginContainer from './LoginContainer';
import { getSafeRedirectUri } from '../../../lib/url';

const EmailRequestPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Form data and status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState<string | null>(null);
  const [nextResendAllowedAt, setNextResendAllowedAt] = useState<string | null>(
    null,
  );

  const [requestVerificationCode] =
    useAuthenticationRequestVerificationCodeMutation();

  // Get redirect URL from query params, with validation
  const redirectUri = getSafeRedirectUri(
    searchParams.get('redirectUri'),
    '/client/home',
  );

  // Handle email form submission
  const handleEmailSubmit = async (data: EmailFormData) => {
    setIsSubmitting(true);

    try {
      const response = await requestVerificationCode({
        variables: {
          input: {
            email: data.email,
          },
        },
      });

      const result = response.data?.authenticationRequestCode;

      if (result?.success) {
        setMaskedEmail(result.maskedEmail);
        setNextResendAllowedAt(result.nextResendAllowedAt);

        console.log('About to navigate to verification page:', {
          verificationId: result.verificationId,
          redirectUri,
          fullPath: `/login/client/verify/${
            result.verificationId
          }?redirectUri=${encodeURIComponent(redirectUri)}`,
        });

        navigate(
          `/login/client/verify/${
            result.verificationId
          }?redirectUri=${encodeURIComponent(redirectUri)}`,
        );
      } else if (result?.errors && result.errors.length > 0) {
        customToast.error(result.errors[0].message);
      } else {
        customToast.error(
          'Failed to send verification code. Please try again.',
        );
      }
    } catch (error) {
      customToast.error('An unexpected error occurred. Please try again.');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <LoginContainer
      title="Client Portal"
      description="Log in to access all of the messages, notes, actions, and resources you're working on with your practitioner."
    >
      <EmailForm
        onSubmit={handleEmailSubmit}
        isSubmitting={isSubmitting}
        maskedEmail={maskedEmail}
        nextResendAllowedAt={nextResendAllowedAt}
        onResendCode={() => {
          customToast.error('Resend code feature not available yet.');
        }}
      />
    </LoginContainer>
  );
};

export default EmailRequestPage;
