import { FC } from 'react';
import Button from '../../../../components/Button';

interface VerifiedStateProps {
  removeSender: () => Promise<void>;
  emailSender: {
    fromName: string;
    fromEmail: string;
  };
  isLoading?: boolean;
}

export const VerifiedState: FC<VerifiedStateProps> = ({
  removeSender,
  emailSender,
  isLoading = false,
}) => {
  return (
    <div className="mb-3 rounded-lg border border-neutral-75 p-4">
      <div className="mb-2 text-caption font-medium text-green-150">
        Verified
      </div>
      <div className="mb-3">
        <div className="text-small-caption font-medium text-neutral-150">
          From name
        </div>
        <div className="mb-2 text-small-caption text-neutral-125">
          {emailSender.fromName}
        </div>
        <div className="text-small-caption font-medium text-neutral-150">
          From email
        </div>
        <div className="mb-3 text-small-caption text-neutral-125">
          {emailSender.fromEmail}
        </div>
      </div>
      <Button
        size="small"
        title={isLoading ? 'Removing...' : 'Remove'}
        theme="destructive"
        noFocus
        onClick={removeSender}
        disabled={isLoading}
      />
    </div>
  );
};
