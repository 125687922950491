import {
  ServiceIcon,
  PatientAccessLevel,
  ServiceTagsDataFragment,
} from '../../../generated/graphql';
import { SearchOption } from '../../components/SearchableCombobox';

export interface Practitioner {
  id: string;
  name: string;
  email: string;
  profileImageUrl?: string;
}

export interface PractitionerSearchOption extends SearchOption {
  practitioner: Practitioner;
}

export interface ServiceBuilderForm {
  isEnabled: boolean;
  isShownOnStorefront: boolean;
  name: string;
  serviceIcon: ServiceIcon;
  serviceIconColorUseCalendlyDefault: boolean;
  calendlyEventColor: string | null;
  slug: string;
  description: string;
  isPaid: boolean;
  price: number;
  duration: number;
  calendlyEventTypeUri: string | null;
  serviceTags: ServiceTagsDataFragment[];
  intakeFormAssessmentId: string;
  patientAccessLevel: PatientAccessLevel;
  practitioners: PractitionerSearchOption[]; // Use our extended type
}

export const MAX_NAME_LENGTH = 50;
export const MAX_DESCRIPTION_LENGTH = 600;

/**
 * Converts GraphQL provider user data to the Practitioner format
 */
export const convertProviderUserToPractitioner = (
  providerUser: any,
): Practitioner => {
  return {
    id: providerUser.id,
    name: providerUser.name,
    email: providerUser.email,
    profileImageUrl: providerUser.profileImageMedia?.url || '',
  };
};

/**
 * Converts GraphQL provider user data to SearchOption format
 * with additional practitioner data for the SearchableCombobox
 */
export const convertProviderUserToSearchOption = (
  providerUser: any,
): PractitionerSearchOption => {
  const practitioner = convertProviderUserToPractitioner(providerUser);
  return {
    value: practitioner.id,
    name: practitioner.name,
    email: practitioner.email,
    practitioner: practitioner,
  };
};
