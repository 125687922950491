import { FC, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CodeVerificationForm, { CodeFormData } from './CodeVerificationForm';
import {
  useAuthenticationVerifyCodeMutation,
  useAuthenticationCodeVerificationQuery,
} from '../../../../generated/graphql';
import { usePatientAuth } from '../../../../contexts/PatientAuthContext';
import { customToast } from '../../../components/ToastAlert/customToast';
import LoginContainer from './LoginContainer';
import { getSafeRedirectUri } from '../../../lib/url';

const CodeVerificationPage: FC = () => {
  const navigate = useNavigate();
  const { verificationId } = useParams<{ verificationId: string }>();
  const [searchParams] = useSearchParams();
  const { updateTokenPayload } = usePatientAuth();

  // Form data and status
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Use the utility function to validate and sanitize the redirect URI
  const redirectUri = getSafeRedirectUri(
    searchParams.get('redirectUri'),
    '/client/home',
  );

  const [verifyCode] = useAuthenticationVerifyCodeMutation();

  // Use query to fetch verification data for the verificationId
  const {
    data: verificationData,
    loading: verificationLoading,
    error: verificationError,
  } = useAuthenticationCodeVerificationQuery({
    variables: { verificationId: verificationId || '' },
    skip: !verificationId,
  });

  // Handle code verification form submission
  const handleCodeSubmit = async (data: CodeFormData) => {
    if (!verificationId) {
      customToast.error('Session expired. Please start over.');
      navigate('/login/client', { replace: true });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await verifyCode({
        variables: {
          input: {
            verificationId: verificationId,
            code: data.code,
            rememberMe: data.rememberMe,
          },
        },
      });

      const result = response.data?.authenticationVerifyCode;

      if (result?.success && result.sessionToken) {
        // Store session token and redirect
        updateTokenPayload({
          authSessionToken: result.sessionToken,
        });
        navigate(redirectUri);
      } else if (result?.errors && result.errors.length > 0) {
        customToast.error(result.errors[0].message);
      } else {
        customToast.error('Failed to verify code. Please try again.');
      }
    } catch (error) {
      customToast.error('An unexpected error occurred. Please try again.');
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleBackToEmail = () => {
    navigate('/login/client', { replace: true });
  };

  const handleInvalidSession = () => {
    customToast.error(
      'Invalid or expired verification session. Please try again.',
    );
    navigate('/login/client', { replace: true });
  };

  // Create form content based on loading/error state
  const renderFormContent = () => {
    // Show loading state while fetching verification data
    if (verificationLoading) {
      return (
        <div className="flex items-center justify-center py-16">
          <div className="text-gray-400 animate-pulse text-subtitle">
            {/* TODO: Add our own loading animation here */}
            Loading...
          </div>
        </div>
      );
    }

    // Handle error or missing data after loading completes
    if (
      verificationError ||
      !verificationData?.authenticationCodeVerification?.maskedEmail
    ) {
      // We can either redirect immediately or render a message before redirecting
      setTimeout(() => handleInvalidSession(), 100);
      return (
        <div className="flex items-center justify-center py-16">
          <div className="text-red-500 text-subtitle">
            Invalid session. Redirecting...
          </div>
        </div>
      );
    }

    // Get masked email directly from query result
    const maskedEmail =
      verificationData?.authenticationCodeVerification?.maskedEmail || null;

    // Return the form when we have valid data
    return (
      maskedEmail && (
        <LoginContainer
          title="Verification required"
          description={
            <>
              Enter the verification code sent to{' '}
              <span className="font-semibold">{maskedEmail}</span>
            </>
          }
        >
          <CodeVerificationForm
            onSubmit={handleCodeSubmit}
            isSubmitting={isSubmitting}
            maskedEmail={maskedEmail}
            onBack={handleBackToEmail}
          />
        </LoginContainer>
      )
    );
  };

  return renderFormContent();
};

export default CodeVerificationPage;
