import { useBrandingCreateVerifiedEmailSenderMutation } from '../../../../../../generated/graphql';
import { SenderFormData } from '../types';
import { customToast } from '../../../../../components/ToastAlert/customToast';

export const useCreateVerifiedEmailSender = (
  onSuccess: () => void,
  setFormErrors: (field: keyof SenderFormData, message: string) => void,
) => {
  const [createVerifiedEmailSender, { loading }] =
    useBrandingCreateVerifiedEmailSenderMutation();

  const createSender = async (data: SenderFormData) => {
    try {
      const response = await createVerifiedEmailSender({
        variables: {
          input: {
            fromName: data.fromName,
            fromEmail: data.fromEmail,
            address: data.address,
            address2: data.address2,
            city: data.city,
            state: data.state,
            zip: data.zip,
            country: data.country,
          },
        },
      });

      const result = response.data?.brandingCreateVerifiedEmailSender;

      if (result?.success) {
        customToast.success(
          'Verification email sent from SendGrid. Please check your inbox for a verification link.',
        );
        onSuccess();
        return true;
      } else if (result?.errors && result.errors.length > 0) {
        result.errors.forEach((error) => {
          if (error.field === 'general') {
            customToast.error(error.message);
          } else if (error.field) {
            setFormErrors(error.field as keyof SenderFormData, error.message);
          }
        });
        return false;
      } else {
        customToast.error('Failed to request verification. Please try again.');
        return false;
      }
    } catch (error: any) {
      const errorMessage =
        error.message || 'Failed to request verification. Please try again.';
      customToast.error(errorMessage);
      return false;
    }
  };

  return {
    createSender,
    loading,
  };
};
