import { DateTime } from 'luxon';
import {
  CommonWhenType,
  NylasCalendarEvent,
} from '../../../../../generated/graphql';
import { logger } from '../../../../../lib/logger';

interface EventTimeData {
  when: {
    startDate: string;
    endDate: string;
    startTime?: string;
    endTime?: string;
    startTimezone?: string;
    endTimezone?: string;
  };
  isAllDay?: boolean;
}

interface EventFormData extends EventTimeData {
  title?: string;
  description?: string;
  location?: string;
  participants?: Array<any>;
}

export const hasDirtyFields = (
  dirtyFields: Record<string, any>,
  fields: string[],
): boolean => {
  return fields.some((field) => {
    // Handle nested fields (e.g., 'when.startDate')
    const parts = field.split('.');
    let current = dirtyFields;

    for (const part of parts) {
      if (!current[part]) return false;
      current = current[part];
    }

    return true;
  });
};

export const serializeEventTime = (
  data: EventTimeData,
): Partial<NylasCalendarEvent> => {
  const serialized: Partial<NylasCalendarEvent> = {};

  logger.debug('🎯 serializeEventTime', data);

  if (data.isAllDay) {
    // if (data.when.startDate === data.when.endDate) {
    //   serialized.when = {
    //     date: data.when.startDate,
    //   };
    // } else {
    //   serialized.when = {
    //     startDate: data.when.startDate,
    //     endDate: data.when.endDate,
    //   };
    // }
    const startDate = data.when.startDate;
    let endDate = data.when.endDate;

    // If startDate and endDate are the same, increment endDate by one day
    if (startDate === endDate) {
      endDate = DateTime.fromISO(startDate).plus({ days: 1 }).toISODate();
    }

    serialized.when = {
      type: CommonWhenType.Datespan,
      startDate,
      endDate,
    };
  } else {
    const startDateTime = DateTime.fromFormat(
      `${data.when.startDate} ${data.when.startTime}`,
      'yyyy-MM-dd HH:mm',
    ).toUTC();
    // For non-all-day events, always use startDate as endDate
    const endDateTime = DateTime.fromFormat(
      `${data.when.startDate} ${data.when.endTime}`,
      'yyyy-MM-dd HH:mm',
    ).toUTC();

    serialized.when = {
      type: CommonWhenType.Timespan,
      startTime: startDateTime.toUnixInteger(),
      endTime: endDateTime.toUnixInteger(),
      startTimezone: data.when.startTimezone,
      endTimezone: data.when.endTimezone,
    };
  }

  return serialized;
};

export const serializeEventUpdate = (
  data: EventFormData,
  dirtyFields: Record<string, any>,
): Partial<NylasCalendarEvent> => {
  const serialized: Partial<NylasCalendarEvent> = {};

  // Handle non-time fields
  if (dirtyFields.title) serialized.title = data.title;
  if (dirtyFields.description) serialized.description = data.description;
  if (dirtyFields.location) serialized.location = data.location;
  if (dirtyFields.participants) serialized.participants = data.participants;

  // Handle time fields if modified
  const timeFieldsChanged = hasDirtyFields(dirtyFields, [
    'isAllDay',
    'when.startDate',
    'when.endDate',
    'when.startTime',
    'when.endTime',
  ]);

  if (timeFieldsChanged) {
    const timeData = serializeEventTime(data);
    serialized.when = timeData.when;
  }

  return serialized;
};
